import {CommonModule} from "@angular/common";
import {Component, Input} from "@angular/core";

@Component({
  selector: "atl-filter-chip",
  templateUrl: "./filter-chip.component.html",
  styleUrls: ["./filter-chip.component.scss"],
  standalone: true,
  imports: [CommonModule]
,})
export class FilterChipComponent {
  @Input() selected!: boolean;
  @Input() title!: string;
  @Input() dot: '·' | '•' = '·';
  @Input() useActiveIcon = true;
  @Input() activeIcon = '/assets/newest-checkmark.svg';
  @Input() count?: number;
}
