import { EChangeRequestStatus, EChangeRequestTab, ISettingsMenu, IUser } from '@atlas-workspace/shared/models';

/**
 * @Cypress
 */
export const navigationMenu: ISettingsMenu[] = [
  { name: EChangeRequestTab.General, id: 'general', disabled: false, route: '' },
  { name: EChangeRequestTab.Customer, id: 'customer', disabled: true, route: '', counter: 0 },
  { name: EChangeRequestTab.Internal, id: 'internal', disabled: false, route: '', counter: 0 },
  { name: EChangeRequestTab.Notes, id: 'notes', disabled: false, route: '' },
  { name: EChangeRequestTab.Offer, id: 'offers', disabled: false, route: '' },
];

export interface IUpdateTable {
  activeOfferId?: number | null;
  activeOfferPrice?: number | null;
  localizedStatus?: string;
  status?: EChangeRequestStatus;
  descriptions?: string;
  responsible?: Partial<IUser> | null;
}

export enum EChangeRequestFormKey {
  Descriptions = 'description',
  Responsible = 'responsible',
}
