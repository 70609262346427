<div class="offer-detail v-scrollbar">
  <div class="offer-detail__header fw-500 fz-14 lh-24 text-black-810 cursor-pointer" (click)="backOffersList()">
    <img class="mr-4" src="/assets/arrow-left-mobile.svg" alt="back" />
    {{ 'Shared.Offers.All_offers' | translate }}
  </div>

  <form [formGroup]="form">
    <div class="offer-detail__control">
      <div class="offer-detail__control-title">
        <img [src]="'assets/text-align-left-gray.svg'" alt="icon" />
        {{ 'Input.Offer_title.Label' | translate }}
      </div>
      <div class="offer-detail__control-input">
        <atl-input
          formControlName="title"
          class="newest-input--number"
          [placeholder]="'Input.Contact_name.Placeholder'"
          [readOnly]="true"
        ></atl-input>
      </div>
    </div>

    <div class="offer-detail__control">
      <div class="offer-detail__control-title">
        <img [src]="'assets/text-align-left-gray.svg'" alt="icon" />
        {{ 'Shared.Reclamation.Input.Description.Label' | translate }}
      </div>
      <div class="offer-detail__control-input">
        <atl-textarea-autoresize
          class="newest-textarea"
          [stringMode]="true"
          [autoresizeOffset]="2"
          formControlName="description"
          [placeholder]="'Shared.Reclamation.Input.Description.Placeholder' | translate"
        ></atl-textarea-autoresize>
      </div>
    </div>

    <div class="offer-detail__control">
      <div class="offer-detail__control-title">
        <img [src]="'assets/hash.svg'" alt="icon" />
        {{ 'Title.Price' | translate }}
      </div>
      <div class="offer-detail__control-input">
        <atl-input
          class="newest-input--number"
          formControlName="price"
          mask="separator.0"
          separatorLimit="100000000000"
          thousandSeparator=" "
          type="text"
          [readOnly]="true"
        ></atl-input>
      </div>
    </div>

    <div class="offer-detail__control">
      <div
        class="offer-detail__control-title"
        [ngbTooltip]="'Title.Offer_expiration' | translate"
        triggers="hover touchstart"
      >
        <img [src]="'assets/calendar-gray.svg'" alt="icon" />
        <span class="ellipsis">{{ 'Title.Offer_expiration' | translate }}</span>
      </div>
      <div class="offer-detail__control-input">
        <atl-inline-date-time-picker
          #datePicker
          formControlName="expirationDate"
          [dateValue]="offer.expirationDate"
        ></atl-inline-date-time-picker>
      </div>
    </div>

    <div class="offer-detail__control">
      <div class="offer-detail__control-title">
        <img [src]="'assets/spinner-gray.svg'" alt="icon" />
        <span>{{ 'Shared.Reclamation.Input.Status.Label' | translate }}</span>
      </div>
      <div class="offer-detail__control-input d-flex">
        <span
          *ngIf="offer.status"
          class="gl-ellipsis-table-item status ml-8"
          [ngClass]="'status__' + statusClasses[offer.status].style"
        >
          {{ offer.localizedStatus }}
        </span>
        <span
          *ngIf="offer.status === statusKeys.OfferDeclined"
          class="offer-detail__details cursor-pointer fz-14 lh-24 fw-400 text-black-810 ml-8"
          (click)="showComment()"
        >
          {{ 'Shared.Reclamation.Details' | translate }}
          <img src="assets/arrow-newest-gray-down.svg" alt="arrow" />
        </span>
      </div>
    </div>

    <div class="offer-detail__control">
      <div class="offer-detail__control-title">
        <img [src]="'assets/attachment_gray.svg'" alt="icon" />
        {{ 'Shared.Reclamation.Input.Attachments.Label' | translate }}
      </div>
      <div class="offer-detail__control-input">
        <atl-dropdown-input-file
          *ngIf="offer.fileResources?.length; else noAttachments"
          formControlName="fileResources"
          dragGroup="fileResources"
          [newOnly]="true"
          [autoSave]="true"
          [disabled]="true"
          (viewTempFilesEmitter)="openPreview($event)"
        ></atl-dropdown-input-file>
        <ng-template #noAttachments>
          <span class="empty-input">
            {{ 'Shared.Reclamation.No_attachments' | translate }}
          </span>
        </ng-template>
      </div>
    </div>
  </form>

  <atl-plan-drawing
    [client]="true"
    [displayFloors]="true"
    [unitId]="unitId"
    [isEditFlow]="false"
    [drawVersion]="false"
    [floorPlan]="floorPlanData"
    [floorType]="unit?.hasOwnFloorPlans ? floorType.UnitFloor : floorType.Floor"
    [floorDrawVersions]="changeRequest.floorDrawVersions"
    [isAlternative]="false"
    [activeVersion]="offer.versionNumber"
    [description]="'Shared.Change_Request.Drawing_mark.Description.Offer'"
  ></atl-plan-drawing>
</div>
<ng-container *ngIf="!loadingDocument">
  <div class="offer-detail__footer" *ngIf="offer.status === statusKeys.Offered">
    <div class="d-flex align-items-center">
      <button [disabled]="isGuest" (click)="declineOffer()" class="button-red mr-16">
        {{ 'Shared.Button_decline' | translate }}
      </button>
      <atl-button-loader
        *ngIf="isFlowBankId"
        (click)="approveOffer()"
        [newest]="true"
        [loading]="isLoading"
        [disabled]="isGuest"
        label="Shared.Button.Accept_and_Sign"
      ></atl-button-loader>
      <atl-button-loader
        *ngIf="!isFlowBankId"
        (click)="approveOffer()"
        [newest]="true"
        [loading]="isLoading"
        [disabled]="isGuest"
        label="Shared.Button.Accept_offer"
      ></atl-button-loader>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="loadingDocument">
  <atl-progress-loader
    (onclose)="closeDocumentLoading()"
    [title]="'Shared.Title.Generating_document' | translate"
  ></atl-progress-loader>
</ng-container>
