<div class="create-offer__header">
  <span>{{ 'Button.New_offer' | translate }}</span>

  <atl-header-icon
    (click)="closeModal()"
    icon="assets/close_newest_gray.svg"
    data-cy="cy-close-btn"
    [ngbTooltip]="'Alt.Close' | translate"
    triggers="hover touchstart"
  ></atl-header-icon>
</div>

<div class="create-offer__form-wrapper v-scrollbar">
  <form [formGroup]="form">
    <div>
      <atl-input
        id="cy-offer-title"
        [placeholder]="'Input.Offer_title.Placeholder'"
        formControlName="offerTitle"
        [label]="'Input.Offer_title.Label'"
        tabindex="0"
      ></atl-input>
    </div>

    <div class="mt-24">
      <atl-custom-text-area
        [capitalize]="true"
        [label]="'Input.Description.Label'"
        [placeholder]="'Input.Type_description'"
        formControlName="offerDescription"
      >
      </atl-custom-text-area>
    </div>

    <div class="mt-24">
      <atl-input
        mask="separator.0"
        [separatorLimit]="separatorLimit"
        thousandSeparator=" "
        [placeholder]="'Shared.Entity.Add_price'"
        formControlName="offerPrice"
        [label]="'Entity.Price'"
        [suffix]="',-'"
        class="w-100"
        [allowNegativeNumbers]="true"
      ></atl-input>
    </div>

    <div class="mt-24">
      <label for="offer-expiration" class="mb-8 d-flex create-offer__label">{{
        'Title.Offer_expiration' | translate
      }}</label>
      <atl-input-date-time-picker
        id="offer-expiration"
        class="create-offer__date-picker"
        formControlName="offerExpiration"
        [placeholder]="'Meeting_Data_Picker.Confirmation_Button' | translate"
        [min]="today"
        [firstDayOfWeek]="1"
      >
      </atl-input-date-time-picker>
    </div>

    <div class="mt-24">
      <atl-drop-down-writing
        id="floor-version"
        formControlName="version"
        [inputTitle]="'Shared.Entity.Floor_plan' | translate"
        [customItemView]="true"
        [customLabelView]="true"
        [customLabelTranslateView]="true"
        [listOfItems]="versionList"
        [bindValue]="'version'"
        [placeholder]="'Shared.Entity.Select_version'"
        position="auto"
        [disabled]="!versionList.length"
      >
        <ng-template atlInputTemplate let-item="item">
          <div class="d-flex align-items-center justify-content-between">
            <span class="w-50 d-flex align-items-center text-black-960">
              <atl-input-radio
                class="pointer-events-none"
                [checked]="form.get('version')?.value === item.version"
                sizeInput="small"
              ></atl-input-radio>
              {{ 'Shared.Entity.Version' | translate }} {{ item.version }}
            </span>
            <span
              #creator
              class="w-50 d-block ellipsis text-black-810"
              [ngbTooltip]="item.creator"
              [disableTooltip]="creator.clientWidth >= creator.scrollWidth"
              container="body"
              triggers="hover touchstart"
            >
              {{ item.date | date: 'dd.MM.YYYY' }} · {{ item.creator }}
            </span>
          </div>
        </ng-template>
        <ng-template atlInputTranslateTemplate let-item="item">
          <div class="d-flex align-items-center justify-content-between">
            <span class="d-flex align-items-center">
              {{ 'Shared.Entity.Version' | translate }} {{ item.version }}
            </span>
          </div>
        </ng-template>
      </atl-drop-down-writing>
    </div>

    <div class="mt-24 mb-24">
      <atl-document-drag-and-drop
        [newestViewImage]="true"
        [label]="'Shared.Messages.Attachments'"
        [format]="acceptedExtensions"
        [asUploadButton]="true"
        formControlName="offerAttachments"
        [maxUploadedFiles]="5"
        [multiple]="true"
      >
      </atl-document-drag-and-drop>
    </div>
  </form>
</div>

<div class="create-offer__footer">
  <button id="cy-offer-cancel" (click)="closeModal()" class="newest-button button-gray mr-16" type="button">
    {{ 'Button.Cancel' | translate }}
  </button>
  <button
    [disabled]="form.invalid"
    id="cy-offer-add"
    [ngClass]="{ 'loading pointer-events-none': isLoading }"
    class="newest-button newest-button-spinner"
    type="button"
    (click)="onSubmit()"
  >
    {{ 'Button.Make_offer' | translate }}
  </button>
</div>
