<!--
  ******************************************************
  @Cypress
  - interaction with next/prev buttons
  - interaction with form controls
  ******************************************************
-->
<div
  class="reclamation__wrapper"
  [class.pinMode]="pinMode"
  (atlCloseModalEsc)="closeModal()"
  atlWindowInnerHeight
  (isMobile)="isMobile($event)"
>
  <div class="reclamation__header" *ngIf="!componentView">
    <div class="reclamation__header-title" data-cy="reclamation-detail-header-title">
      {{ 'Shared.Reclamation.Reclamation_details' | translate: { title: reclamation ? (reclamation.identifier | afterSlash) : '' } }}
      {{ reclamation && showUnitIdentifier ? '(' + reclamation.unit.identifier  + ')' : '' }}
    </div>
    <div class="reclamation__header-action">
      <div>
        <ng-container>
          <div *ngIf="mobileView && !disableOpenReclamation" (click)="openCustomerMessages()" class="position-relative">
            <atl-custom-icon
              (click)="openCustomerMessages()"
              [icon]="messagesIcon"
              [size]="16"
              class="reclamation__header-action-link"
              [class.active]="activeTab === reclamationTabs.Messages"
            ></atl-custom-icon>
            <span *ngIf="customerMessageCount" class="item-counter">{{ customerMessageCount }}</span>
          </div>
          <ng-container *ngIf="!disableSwitchingReclamation && !disableOpenReclamation">
            <ng-container *ngIf="isModalNavigation">
              <atl-custom-icon
                (click)="onChangeReclamation(positionControl.Next)"
                class="reclamation__header-action-link"
                data-cy="reclamation-detail-action-next"
                [icon]="arrowDownIcon"
                [ngbTooltip]="'Shared.Alt.Next' | translate"
                [openDelay]="tooltipDelay"
                placement="bottom"
              ></atl-custom-icon>
              <atl-custom-icon
                (click)="onChangeReclamation(positionControl.Previous)"
                class="reclamation__header-action-link"
                data-cy="reclamation-detail-action-prev"
                [icon]="arrowUpIcon"
                [ngbTooltip]="'Shared.Alt.Prev' | translate"
                [openDelay]="tooltipDelay"
                placement="bottom"
              ></atl-custom-icon>
            </ng-container>
          </ng-container>
          <atl-custom-icon
            (click)="openActivities()"
            class="reclamation__header-action-link"
            [icon]="activitiesIcon"
            [class.active]="activeTab === reclamationTabs.Activities"
            [ngbTooltip]="'Shared.Entity.Activities' | translate"
            [openDelay]="tooltipDelay"
            placement="bottom"
          ></atl-custom-icon>
          <atl-more-dropdown [header]="false" [footer]="false">
            <ng-container ngProjectAs="[item]">
              <div ngbDropdownItem (click)="copyUrl()" class="more-dropdown__item">
                <div class="d-flex align-items-center">
                  <img src="/assets/unit_link.svg" alt="link option" />
                  <span class="fz-14 lh-24">{{ 'Entity.Link_for_admin_portal' | translate: {title: ('Title.Reclamation' | translate)} }}</span>
                </div>
                <img class="d-none" src="/assets/shift-command.svg" alt="key icon" />
              </div>
              <div ngbDropdownItem *ngIf="!isContractorRole" (click)="openUpload()" class="more-dropdown__item" [class.disabled]="reclamation?.archived">
                <div class="d-flex align-items-center">
                  <img src="/assets/attachment_gray.svg" alt="link option" />
                  <span class="fz-14 lh-24">{{ 'Shared.Entity.Add_attachments' | translate }}</span>
                </div>
              </div>
            </ng-container>
          </atl-more-dropdown>
        </ng-container>
        <atl-custom-icon
          [icon]="closeIcon"
          [size]="16"
          class="reclamation__header-action-link"
          (click)="closeModal()"
          [ngbTooltip]="'Alt.Close' | translate"
          [openDelay]="tooltipDelay"
          placement="bottom"
          data-cy="reclamation-detail-close"
        ></atl-custom-icon>
      </div>
    </div>
  </div>

  <div class="reclamation__tabs z-index-4" [class.mt-16]="componentView">
    <atl-simple-tabs
      class="tabs"
      [activeTab]="activeTab"
      [tabs]="viewNavigationMenu"
      [routeNavigation]="routeNavigation"
      [useTooltip]="true"
      (selectingTab)="selectAnotherTab($event)"
      [inlineTabs]="true"
    ></atl-simple-tabs>
  </div>

  <ng-template [ngIf]="reclamationTabs.General === activeTab">
    <div class="reclamation__container v-scrollbar p-24">
      <ng-container *ngIf="!reclamationLoading; else spinnerBlock">
        <ng-container *ngIf="reclamation?.archived" [ngTemplateOutlet]="warningBlock"></ng-container>
        <ng-container *ngIf="form">
          <form class="reclamation__form" [formGroup]="form">
            <div class="reclamation__divider-block">
              <!-- Status -->
              <div class="reclamation__control align-items-start">
                <div class="reclamation__control-title">
                  <img [src]="'assets/spinner-gray.svg'" alt="icon" />{{
                    'Shared.Reclamation.Input.Status.Label' | translate
                  }}
                  <div
                    *ngIf="reclamation.status === statusKeys.Declined || reclamation.status === statusKeys.Disputed"
                    class="reclamation__event-detail cursor-pointer w-100"
                    (click)="openCommentModal(true, reclamation.status)"
                  >
                    <atl-custom-icon
                      [ngbTooltip]="'Shared.Reclamation.Details' | translate"
                      [openDelay]="tooltipDelay"
                      container="body"
                      class="arrow-icon"
                      [icon]="messageIcon"
                    ></atl-custom-icon>
                  </div>
                </div>
                <div [formGroup]="formStatus" class="reclamation__control-input">
                  <atl-confirm-dropdown
                    formControlName="status"
                    data-cy="reclamation-detail-status"
                    [listOfItems]="statusChangeList"
                    [searchKey]="['title']"
                    [bindLabel]="'title'"
                    [bindValue]="'status'"
                    confirmTitle="Shared.Entity.Confirm_status"
                    [disabled]="!reclamation.availableStatuses.length || !isOnline"
                    #statusField
                    [useBody]="false"
                    [placement]="['bottom']"
                  >
                    <ng-template atlInputLabelTemplate let-item="item" let-selected="selected">
                      <div
                        *ngIf="item"
                        class="fz-14 lh-24 text-black-960 reclamation__status"
                        [ngClass]="'reclamation__status-' + statusList[item.status]?.style"
                        [attr.data-cy-id]="item.status"
                      >
                        <span class="reclamation__status-indicator"></span>
                        {{ item.title | translate }}
                      </div>
                      <img
                        *ngIf="selected"
                        class="item-checkbox"
                        src="/assets/multiple_select_checkbox.svg"
                        alt="Check state"
                      />
                    </ng-template>
                  </atl-confirm-dropdown>
                </div>
              </div>
              <!-- Responsible -->
              <div class="reclamation__control">
                <div class="reclamation__control-title capitalize">
                  <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{
                    'Shared.Reclamation.Input.Responsible.Label' | translate
                  }}
                </div>
                <div class="reclamation__control-input">
                  <atl-drop-down-v2
                    class="multiple-select-component"
                    data-cy="reclamation-detail-responsible"
                    formControlName="responsible"
                    [listOfItems]="users"
                    [bindLabel]="'name'"
                    [searchParams]="['name', 'email']"
                    [placeholder]="'Shared.Reclamation.Input.Responsible.Placeholder'"
                    [notFoundText]="'Shared.Entity.Not_found'"
                    [useCustomItemView]="true"
                    [useCustomLabelView]="true"
                    [disabled]="reclamation?.archived"
                  >
                    <ng-template atlInputLabelTemplate let-item="item" let-index="index">
                      <div class="multiple-select-user-card-wrapper">
                        <div>
                          <atl-firm-icon
                            class="mr-4"
                            [model]="item"
                            nameKey="name"
                            [isUser]="true"
                            [isMonochrome]="true"
                            [size]="24"
                          ></atl-firm-icon>
                          <span
                            class="ellipsis"
                            [ngbTooltip]="item.name"
                            [openDelay]="tooltipDelay"
                            [placement]="index === 0 ? 'bottom' : 'top'"
                            [disableTooltip]="item.name.length < longNameTruncate"
                            data-cy-label
                            container="body"
                            >{{ item?.name | truncate: longNameTruncate }}</span
                          >
                          <img
                            class="multiple-select-user-card-remove"
                            src="/assets/multiple_remove_icon.svg"
                            alt="Remove icon"
                            (click)="onRemoveSelectedItem('responsible', item)"
                          />
                        </div>
                        <img
                          class="multiple-select-user-card-checkbox"
                          src="/assets/multiple_select_checkbox.svg"
                          alt="Check state"
                        />
                      </div>
                    </ng-template>
                    <ng-template atlInputTemplate let-item="item" let-selected="selected" let-index="index">
                      <div class="multiple-select-user-card-wrapper">
                        <div>
                          <div
                            class="multiple-select-user-card-icon mr-4"
                            [ngStyle]="{ 'background-color': item.color, border: '1px solid ' + item.borderColor }"
                          >
                            <span>{{ item?.name | firmPrefix }}</span>
                          </div>
                          <div>
                            <p
                              class="reclamation-dropdown-user-name fz-14 lh-24 fw-400 ellipsis"
                              [class.selected]="selected"
                              [ngbTooltip]="item.name"
                              [openDelay]="tooltipDelay"
                              [placement]="index === 0 ? 'bottom' : 'top'"
                              [disableTooltip]="item.name.length < longNameTruncate"
                              container="body"
                            >
                              {{ item?.name | truncate: longNameTruncate }}
                            </p>
                            <p
                              class="reclamation-dropdown-user-email fz-12 lh-16 fw-400 ellipsis"
                              [ngbTooltip]="item.email"
                              [openDelay]="tooltipDelay"
                              [placement]="index === 0 ? 'bottom' : 'top'"
                              [disableTooltip]="item.email.length < longNameTruncate"
                              container="body"
                            >
                              {{ item?.email | truncate: longEmailTruncate }}
                            </p>
                          </div>
                          <img
                            class="multiple-select-user-card-remove"
                            src="/assets/multiple_remove_icon.svg"
                            alt="Remove icon"
                            (click)="onRemoveSelectedItem('responsible', item)"
                          />
                        </div>
                        <img
                          class="multiple-select-user-card-checkbox"
                          src="/assets/multiple_select_checkbox.svg"
                          alt="Check state"
                        />
                      </div>
                    </ng-template>
                  </atl-drop-down-v2>
                </div>
              </div>
              <!-- Contractor -->
              <div class="reclamation__control contractor">
                <div class="reclamation__control-title">
                  <img [src]="'assets/dropdown-circle.svg'" alt="icon" />
                  <span
                    [ngbTooltip]="'Shared.Reclamation.Input.Contractor_responsible.Label' | translate"
                    container="body"
                    class="d-block ellipsis"
                    >
                    {{ 'Shared.Reclamation.Input.Contractor_responsible.Label' | translate }}
                  </span>
                  <img
                    [ngbTooltip]="'Reclamation.Contractor.Info_tooltip' | translate"
                    placement="bottom"
                    container="body"
                    class="reclamation__control-info ml-8"
                    src="assets/infos_icon.svg"
                    alt="icon"
                  />
                </div>
                <div
                  class="reclamation__control-input position-relative"
                  [class.opened]="contractorsField.dropdownRef.isOpen()"
                >
                  <atl-confirm-dropdown
                    #contractorsField
                    formControlName="contractor"
                    data-cy="reclamation-detail-contractor"
                    [listOfItems]="contractors"
                    [searchKey]="['name', 'email', 'companyName']"
                    confirmTitle="Button.Confirm_contractor"
                    [disabled]="reclamation?.archived"
                    [bindLabel]="'id'"
                    class="contractor-dropdown"
                    [useCustomButtonVal]="true"
                    [allowEmptyVal]="true"
                    [placeholder]="'Shared.Reclamation.Input.Responsible.Placeholder' | translate"
                    [customClass]="pinMode ? 'contractor-dropdown--protocol' : ''"
                    [useBody]="false"
                    [placement]="['bottom']"
                  >
                    <ng-template atlInputLabelTemplate let-item="item" let-selected="selected" let-index="index">
                      <div class="multiple-select-user-card-wrapper contractor-width">
                        <div class="w-100 d-flex justify-content-between" *ngIf="!item?.firstKeyContact" data-cy-type="contractor">
                          <div class="d-flex w-50 mr-16 min-w-0">
                            <atl-firm-icon
                              *ngIf="item"
                              [model]="item"
                              [isUser]="true"
                              [isDropdown]="true"
                              nameKey="name"
                              class="mr-8"
                            >
                            </atl-firm-icon>
                            <p
                              class="reclamation-dropdown-user-name fz-14 lh-24 fw-400 ellipsis"
                              [class.selected]="selected"
                              [ngbTooltip]="item?.name"
                              [openDelay]="tooltipDelay"
                              [placement]="index === 0 ? 'bottom' : 'top'"
                              [disableTooltip]="item?.name?.length < longNameTruncate"
                            >
                              {{ item?.name | truncate: longNameTruncate }}
                            </p>
                          </div>
                          <div class="d-flex flex-shrink-0 w-50">
                            <span class="fz-14 lh-24 fw-400 text-black-810 d-block ellipsis">
                              {{ item?.companyName }}
                            </span>
                            <img
                              class="multiple-select-user-card-remove"
                              src="/assets/multiple_remove_icon.svg"
                              alt="Remove icon"
                              (click)="onRemoveSelectedItem('contractor', item)"
                            />
                          </div>
                        </div>
                        <div *ngIf="item?.firstKeyContact" data-cy-type="contractor-company">
                          <img class="company-icon mr-8" src="assets/bag-gray.svg" alt="icon" />
                          <div>
                            <p
                              class="reclamation-dropdown-user-name fz-14 lh-24 fw-400 ellipsis"
                              [class.selected]="selected"
                              [ngbTooltip]="item?.name"
                              [openDelay]="tooltipDelay"
                              [placement]="index === 0 ? 'bottom' : 'top'"
                              [disableTooltip]="item?.name?.length < longNameTruncate"
                            >
                              {{ item?.name | truncate: longNameTruncate }}
                            </p>
                          </div>
                          <img
                            class="multiple-select-user-card-remove"
                            src="/assets/multiple_remove_icon.svg"
                            alt="Remove icon"
                            (click)="onRemoveSelectedItem('contractor', item)"
                          />
                        </div>
                        <img
                          *ngIf="selected"
                          class="item-checkbox"
                          src="/assets/multiple_select_checkbox.svg"
                          alt="Check state"
                        />
                      </div>
                    </ng-template>
                    <ng-template atlInputTemplate let-item="item" let-index="index">
                      <div class="multiple-select-user-card-wrapper">
                        <div>
                          <ng-container *ngIf="item?.adminId">
                            <atl-firm-icon [model]="reclamation?.contractor" [isUser]="true" [isDropdown]="true" nameKey="name" class="mr-4">
                            </atl-firm-icon>
                            <span
                              class="ellipsis"
                              [ngbTooltip]="item?.name"
                              [openDelay]="tooltipDelay"
                              [placement]="index === 0 ? 'bottom' : 'top'"
                              [disableTooltip]="item?.name?.length < longNameTruncate"
                            >
                              {{ item?.name | truncate: longNameTruncate }} ·
                            </span>
                          </ng-container>
                          <span
                            [class.with-contractor]="item?.adminId"
                            data-cy-label
                          >{{ item?.adminId ? item?.companyName : item?.name }}</span>
                          <img
                            class="multiple-select-user-card-remove"
                            src="/assets/multiple_remove_icon.svg"
                            alt="Remove icon"
                            (click)="onRemoveSelectedItem('contractor', item)"
                          />
                        </div>
                        <img
                          class="multiple-select-user-card-checkbox"
                          src="/assets/multiple_select_checkbox.svg"
                          alt="Check state"
                        />
                      </div>
                    </ng-template>
                  </atl-confirm-dropdown>
                  <atl-users-viewer
                    class="contractor-viewer"
                    *ngIf="reclamation.contractor"
                    [hoverOption]="true"
                    [users]="[reclamation.contractor]"
                  ></atl-users-viewer>
                </div>
              </div>
              <!-- Due_date -->
              <div class="reclamation__control">
                <div class="reclamation__control-title">
                  <img [src]="'assets/calendar-gray.svg'" alt="icon" />{{
                    'Shared.Reclamation.Input.Deadline.Label' | translate
                  }}
                </div>
                <div class="reclamation__control-input lh-32 fz-14">
                  <atl-inline-date-time-picker
                    class="reclamation__datepicker"
                    data-cy="reclamation-detail-duedate"
                    formControlName="dueDate"
                    [minDate]="today"
                    [dateValue]="reclamation?.dueDate"
                    [placeholder]="'Shared.Reclamation.Input.Deadline.Placeholder' | translate"
                    [disabled]="reclamation?.archived"
                    [secondInput]="true"
                    [setDateButton]="true"
                    [firstDayOfWeek]="1"
                  >
                  </atl-inline-date-time-picker>
                </div>
              </div>
            </div>
            <div class="mt-24">
              <!-- Project -->
              <div
                class="reclamation__control align-items-center"
                *ngIf="reclamationScope === reclamationScopesList.Global"
              >
                <div class="reclamation__control-title">
                  <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{ 'Entity.Project_Name' | translate }}
                </div>
                <div class="reclamation__control-input project">
                  <span class="fz-14 lh-24 fw-400 ml-8">{{ reclamation.project.name | translate }}</span>
                </div>
              </div>
              <!-- Unit -->
              <div class="reclamation__control">
                <div class="reclamation__control-title">
                  <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{
                    'Shared.Reclamation.Input.Unit.Label' | translate
                  }}
                </div>
                <div class="reclamation__control-input unit">
                  <atl-drop-down-v2
                    class="multiple-select-component"
                    data-cy="reclamation-detail-unit"
                    formControlName="unit"
                    [listOfItems]="units"
                    [bindLabel]="'identifier'"
                    [placeholder]="'Shared.Reclamation.Input.Unit.Placeholder'"
                    [notFoundText]="'Shared.Entity.Not_found'"
                    [useCustomItemView]="true"
                    [useCustomLabelView]="true"
                  >
                    <ng-template atlInputLabelTemplate atlInputTemplate let-item="item">
                      <div class="multiple-select-user-card-wrapper">
                        <div>
                          <span data-cy-label>{{ item?.identifier }}</span>
                          <img
                            class="multiple-select-user-card-remove"
                            src="/assets/multiple_remove_icon.svg"
                            alt="Remove icon"
                            (click)="onRemoveSelectedItem('unit', item)"
                          />
                        </div>
                        <img
                          class="multiple-select-user-card-checkbox"
                          src="/assets/multiple_select_checkbox.svg"
                          alt="Check state"
                        />
                        <span *ngIf="ownersList?.length" class="text-gray-890 ml-8 mr-8">·</span>
                        <atl-users-viewer *ngIf="ownersList?.length" [users]="ownersList"></atl-users-viewer>
                      </div>
                    </ng-template>
                  </atl-drop-down-v2>
                </div>
              </div>
              <!-- Address -->
              <div *ngIf="reclamation?.unit?.address" class="reclamation__control align-items-start">
                <div class="reclamation__control-title">
                  <img [src]="'assets/map_pin_control.svg'" alt="icon" />{{ 'Input.Address.Label' | translate }}
                </div>
                <div class="reclamation__control-input pl-8 pt-4">
                  <span class="fz-14 lh-24 word-break">{{ reclamation?.unit?.address }}</span>
                </div>
              </div>
              <!-- Category -->
              <div class="reclamation__control">
                <div class="reclamation__control-title">
                  <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{
                    'Shared.Reclamation.Input.Category.Label' | translate
                  }}
                </div>
                <div class="reclamation__control-input">
                  <atl-drop-down-v2
                    [disabled]="reclamation?.archived"
                    class="multiple-select-component category"
                    data-cy="reclamation-detail-category"
                    formControlName="category"
                    [listOfItems]="categories"
                    [bindLabel]="'name'"
                    [placeholder]="'Shared.Reclamation.Input.Category.Placeholder'"
                    [notFoundText]="'Shared.Entity.Not_found'"
                    [useCustomItemView]="true"
                    [useCustomLabelView]="true"
                    [createNotFound]="false"
                  >
                    <ng-template atlInputLabelTemplate atlInputTemplate let-item="item">
                      <div class="multiple-select-user-card-wrapper">
                        <div>
                          <span data-cy-label>{{ item?.name }}</span>
                          <img
                            class="multiple-select-user-card-remove"
                            src="/assets/multiple_remove_icon.svg"
                            alt="Remove icon"
                            (click)="onRemoveSelectedItem('category', item)"
                          />
                        </div>
                        <img
                          class="multiple-select-user-card-checkbox"
                          src="/assets/multiple_select_checkbox.svg"
                          alt="Check state"
                        />
                      </div>
                    </ng-template>
                  </atl-drop-down-v2>
                </div>
              </div>
              <!-- Type -->
              <div class="reclamation__control">
                <div class="reclamation__control-title">
                  <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{
                    'Shared.Reclamation.Input.Type.Label' | translate
                  }}
                </div>
                <div class="reclamation__control-input">
                  <atl-drop-down-v2
                    class="multiple-select-component type"
                    data-cy="reclamation-detail-type"
                    formControlName="type"
                    [listOfItems]="types"
                    [bindLabel]="'name'"
                    [placeholder]="'Shared.Reclamation.Input.Type.Placeholder'"
                    [notFoundText]="'Shared.Entity.Not_found'"
                    [useCustomItemView]="true"
                    [useCustomLabelView]="true"
                    [createNotFound]="false"
                    [disabled]="reclamation?.archived"
                  >
                    <ng-template atlInputLabelTemplate atlInputTemplate let-item="item">
                      <div class="multiple-select-user-card-wrapper">
                        <div>
                          <span data-cy-label>{{ item?.name }}</span>
                          <img
                            class="multiple-select-user-card-remove"
                            src="/assets/multiple_remove_icon.svg"
                            alt="Remove icon"
                            (click)="onRemoveSelectedItem('type', item)"
                          />
                        </div>
                        <img
                          class="multiple-select-user-card-checkbox"
                          src="/assets/multiple_select_checkbox.svg"
                          alt="Check state"
                        />
                      </div>
                    </ng-template>
                  </atl-drop-down-v2>
                </div>
              </div>
              <!-- Description -->
              <div class="reclamation__control align-items-start">
                <div class="reclamation__control-title position-relative">
                  <img [src]="'assets/text-align-left-gray.svg'" alt="icon" />{{
                    'Shared.Reclamation.Input.Description.Label' | translate
                  }}
                </div>
                <div class="reclamation__control-input z-index-3">
                  <atl-textarea-autoresize
                    formControlName="description"
                    class="newest-textarea reclamation-description"
                    data-cy="reclamation-detail-description"
                    [stringMode]="true"
                    [autoresizeOffset]="2"
                    [placeholder]="'Shared.Reclamation.Input.Description.Placeholder' | translate"
                    [maxLength]="500"
                    [tabindex]="0"
                    (blurEvent)="updateReclamation()"
                    id="reclamation-description"
                    [disabled]="reclamation?.archived"
                    [class.disabled]="reclamation?.archived"
                    [absolutePosition]="true"
                  ></atl-textarea-autoresize>
                </div>
              </div>
              <!-- Room -->
              <div class="reclamation__control room d-none" *ngIf="rooms.length">
                <div class="reclamation__control-title">
                  <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{
                    'Shared.Reclamation.Input.Room.Label' | translate
                  }}
                </div>
                <div class="reclamation__control-input">
                  <atl-drop-down-v2
                    class="multiple-select-component"
                    data-cy="reclamation-detail-room"
                    formControlName="room"
                    [listOfItems]="rooms"
                    [bindLabel]="'name'"
                    [placeholder]="'Shared.Reclamation.Input.Room.Placeholder'"
                    [notFoundText]="'Shared.Entity.Not_found'"
                    [useCustomItemView]="true"
                    [useCustomLabelView]="true"
                    [disabled]="reclamation?.archived"
                  >
                    <ng-template atlInputLabelTemplate atlInputTemplate let-item="item" let-index="index">
                      <div class="multiple-select-user-card-wrapper">
                        <div>
                          <span
                            class="ellipsis"
                            [ngbTooltip]="item.name"
                            [openDelay]="tooltipDelay"
                            [placement]="index === 0 ? 'bottom' : 'top'"
                            [disableTooltip]="item.name.length < longNameTruncate"
                            data-cy-label
                            >{{ item?.name | truncate: longNameTruncate }}</span
                          >
                          <img
                            class="multiple-select-user-card-remove"
                            src="/assets/multiple_remove_icon.svg"
                            alt="Remove icon"
                            (click)="onRemoveSelectedItem('room', item)"
                          />
                        </div>
                        <img
                          class="multiple-select-user-card-checkbox"
                          src="/assets/multiple_select_checkbox.svg"
                          alt="Check state"
                        />
                      </div>
                    </ng-template>
                  </atl-drop-down-v2>
                </div>
              </div>
              <!-- Product -->
              <div class="reclamation__control d-none" *ngIf="products.length || (isContractorRole && reclamation.wishlistItem)">
                <div class="reclamation__control-title">
                  <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{
                    'Shared.Reclamation.Input.Product.Label' | translate
                  }}
                </div>
                <div class="reclamation__control-input">
                  <atl-drop-down-v2
                    class="multiple-select-component"
                    data-cy="reclamation-detail-product"
                    formControlName="product"
                    [listOfItems]="products"
                    [bindLabel]="'name'"
                    [placeholder]="'Shared.Reclamation.Input.Product.Placeholder'"
                    [notFoundText]="'Shared.Entity.Not_found'"
                    [useCustomItemView]="true"
                    [useCustomLabelView]="true"
                    [disabled]="reclamation?.archived"
                  >
                    <ng-template atlInputLabelTemplate let-item="item" let-index="index">
                      <div class="multiple-select-user-card-wrapper">
                        <div>
                          <div class="fw-400 lh-24 fz-14">
                            <img
                              [src]="item?.preview ? item.preview.filename.w260.url : 'assets/no_images.png'"
                              class="reclamation-dropdown-label__product br-8 mr-8 object-fit-cover"
                              alt="Image item"
                            />
                            <span
                              class="ellipsis"
                              [ngbTooltip]="item.name"
                              [openDelay]="tooltipDelay"
                              [placement]="index === 0 ? 'bottom' : 'top'"
                              [disableTooltip]="item.name.length < longNameTruncate"
                              data-cy-label
                              >{{ item?.name | truncate: longNameTruncate }}</span
                            >
                          </div>
                          <img
                            class="multiple-select-user-card-remove"
                            src="/assets/multiple_remove_icon.svg"
                            alt="Remove icon"
                            (click)="onRemoveSelectedItem('product', item)"
                          />
                        </div>
                      </div>
                    </ng-template>
                    <ng-template atlInputTemplate let-item="item" let-index="index">
                      <div class="multiple-select-user-card-wrapper">
                        <div>
                          <div class="fw-400 lh-24 fz-14">
                            <img
                              [src]="item?.preview ? item.preview.filename.w260.url : 'assets/no_images.png'"
                              class="reclamation-dropdown-item__product br-8 mr-8 object-fit-cover"
                              alt="Image item"
                            />
                            <span
                              class="text-black-960 ellipsis"
                              [ngbTooltip]="item.name"
                              [openDelay]="tooltipDelay"
                              [placement]="index === 0 ? 'bottom' : 'top'"
                              [disableTooltip]="item.name.length < longNameTruncate"
                              data-cy-label
                              >{{ item?.name | truncate: longNameTruncate }}</span
                            >
                          </div>
                          <img
                            class="multiple-select-user-card-remove"
                            src="/assets/multiple_remove_icon.svg"
                            alt="Remove icon"
                            (click)="onRemoveSelectedItem('product', item)"
                          />
                        </div>
                        <img
                          class="multiple-select-user-card-checkbox"
                          src="/assets/multiple_select_checkbox.svg"
                          alt="Check state"
                        />
                      </div>
                    </ng-template>
                  </atl-drop-down-v2>
                </div>
              </div>
              <!-- Meeting -->
              <ng-container>
                <div class="reclamation__control" *ngIf="reclamation.meeting?.meetingName">
                  <div class="reclamation__control-title">
                    <img [src]="'assets/dropdown-circle.svg'" alt="icon" />
                    {{ 'Shared.Reclamation.Input.Meeting.Label' | translate }}
                  </div>
                  <div
                    class="reclamation__control-input d-flex min-w-0"
                    [ngbTooltip]="reclamation.meeting.meetingName"
                    [disableTooltip]="meetingName.clientWidth >= meetingName.scrollWidth"
                  >
                    <span #meetingName class="d-block ellipsis ml-8 lh-32 fz-14">{{ reclamation.meeting.meetingName }}</span>
                  </div>
                </div>
                <!-- Reported -->
                <div class="reclamation__control">
                  <div class="reclamation__control-title">
                    <img [src]="'assets/calendar-gray.svg'" alt="icon" />{{
                      'Shared.Reclamation.Input.Reported.Label' | translate
                    }}
                  </div>
                  <div class="reclamation__control-input">
                    <span class="ml-8 lh-32 fz-14">{{
                      form.controls['reported'].value | date: 'dd.MM.yyyy · H:mm'
                    }}</span>
                  </div>
                </div>
              </ng-container>
              <!-- Attachments -->

              <div class="reclamation__control">
                <div class="reclamation__control-title">
                  <img [src]="'assets/attachment_gray.svg'" alt="icon" />{{
                    'Shared.Reclamation.Input.Attachments.Label' | translate
                  }}
                </div>
                <div class="reclamation__control-input lh-32 fz-14">
                  <atl-dropdown-input-file
                    #fileDropdownRef
                    data-cy="reclamation-detail-attachments"
                    [imagePreview]="true"
                    [placeholder]="'Shared.Entity.Add_attachments' | translate"
                    [newOnly]="false"
                    [autoSave]="true"
                    formControlName="attachments"
                    [format]="acceptedExtensions"
                    (viewTempFilesEmitter)="openPreview($event)"
                    (deleteExistingFile)="removeExistingFile($event)"
                    (updateFilePositionsEmitter)="updateFilePositions($event)"
                    (addNewFilesToExisting)="updateReclamationAttachments($event)"
                    [disabled]="reclamation?.archived"
                  ></atl-dropdown-input-file>
                </div>
              </div>
            </div>

            <ng-container *ngIf="floorPlanData && !pinMode">
              <atl-plan-mark
                [floorPlan]="floorPlanData"
                (setMarkEvent)="setMark($event)"
                [mark]="mark"
                (redirectToUnit)="redirectToUnit()"
                [disabled]="isDisabledMark"
                [isContractor]="isContractorRole"
              ></atl-plan-mark>
            </ng-container>
          </form>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>

  <ng-template [ngIf]="reclamationTabs.Messages === activeTab">
    <atl-reclamation-admin-messages
      *ngIf="reclamation?.unit"
      [threadViewComponent]="threadViewComponent"
      [reclamation]="reclamation"
      [reclamationScope]="reclamationScope"
      [disabled]="reclamation?.archived"
      [projectId]="projectId"
      [isContractor]="isContractorRole"
    ></atl-reclamation-admin-messages>
  </ng-template>

  <ng-template [ngIf]="reclamationTabs.InternalNotes === activeTab">
    <atl-reclamation-admin-internal-notes
      *ngIf="reclamation?.unit"
      [projectId]="projectId"
      [threadViewComponent]="threadViewComponent"
      [reclamation]="reclamation"
      [reclamationScope]="reclamationScope"
      [disabled]="reclamation?.archived"
      [isContractor]="isContractorRole"
      [openTab]="openSubTab"
      (setInternalActiveTab)="setInternalActiveTab($event)"
    ></atl-reclamation-admin-internal-notes>
  </ng-template>

  <ng-template [ngIf]="reclamationTabs.Activities === activeTab">
    <atl-reclamation-admin-activities [reclamationData]="reclamation"></atl-reclamation-admin-activities>
  </ng-template>

  <div 
    *ngIf="formNotes" 
    class="reclamation__notes" 
    [ngClass]="{
      'd-none': reclamationTabs.Notes !== activeTab,
      'compoentView': componentView
    }"
  >
    <ng-container *ngIf="reclamation?.archived" [ngTemplateOutlet]="warningBlock"></ng-container>
    <atl-text-editor-wide
      [class.pin-mode]="pinMode"
      [valControl]="formNotes.controls.notes"
      [apiKey]="apiKey"
      [config]="editorInitConfig"
      [disabled]="reclamation?.archived"
    ></atl-text-editor-wide>
  </div>
</div>

<ng-template #spinnerBlock>
  <div class="reclamation__list-spinner h-100 d-flex align-items-center justify-content-center">
    <span class="custom-spinner custom-spinner-48"></span>
  </div>
</ng-template>

<ng-template #warningBlock>
  <div class="reclamation__warning">
    <div>
      <atl-custom-icon [icon]="warningIcon"></atl-custom-icon>
    </div>
    <div class="reclamation__warning-text fz-14 lh-24">
      <span class="fw-600">{{ 'Entity.Warning' | translate }}: </span>
      {{ 'Reclamation.Warning.Ownership_is_transferred' | translate }}
    </div>
  </div>
</ng-template>
