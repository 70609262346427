import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'atl-round-dropdown',
  templateUrl: './round-dropdown.component.html',
  styleUrls: ['./round-dropdown.component.scss'],
  standalone: true,
  imports: [CommonModule, NgbDropdownModule],
})
export class RoundDropdownComponent {
  @Input() icon = '';
}
