import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {interval, of} from 'rxjs';
import {delay, map, take} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'atl-progress-loader',
  templateUrl: './progress-loader.component.html',
  styleUrls: ['./progress-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressLoaderComponent implements OnInit {
  constructor(private readonly cdr: ChangeDetectorRef) {}
  @Input() readonly title!: string;
  @Input() readonly total!: number;
  @Input() set progress(num: number) {
    this.percentProgressBar(num);
  }
  @Output() private readonly onclose: EventEmitter<void> = new EventEmitter<void>();

  completedPercentage = 0;

  ngOnInit(): void {
    if (this.total) return;
    this.animateProgressBar();
  }

  close(): void {
    this.onclose.emit();
  }

  getProgressBarColor(): string {
    return this.completedPercentage > 0 ? 'var(--color-gray-735)' : 'transparent';
  }

  private percentProgressBar(progress: number): void {
    this.completedPercentage = progress;
    this.cdr.markForCheck();
    if (progress < 100) return;
    of(null).pipe(take(1), delay(5000)).subscribe(() => this.close());
  }

  private animateProgressBar(): void {
    const duration = 4000;
    const intervalStep = 40;
    const steps = duration / intervalStep;
    const targetPercentage = 90;

    const animation$ = interval(intervalStep).pipe(
      take(steps),
      map(step => Math.floor(step * (targetPercentage / steps)))
    );

    animation$.pipe(untilDestroyed(this)).subscribe(step => {
      this.completedPercentage = step;
      this.cdr.markForCheck();
    });
  }

}
