import { Component, ComponentFactoryResolver, Input, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ClientChangeRequestModel, EThreadScope, UnitUserModel } from '@atlas-workspace/shared/models';

@Component({
  selector: 'atl-change-request-customer-notes',
  templateUrl: './change-request-customer-notes.component.html',
  styleUrls: ['./change-request-customer-notes.component.scss'],
})
export class ChangeRequestCustomerNotesComponent implements OnInit {
  @Input() public threadViewComponent!: Type<any>;
  @Input() public changeRequest!: ClientChangeRequestModel;

  @Input() private unit!: UnitUserModel;
  @Input() public disabled = false;

  @ViewChild('threadView', { static: true, read: ViewContainerRef })
  private vcRef!: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    this.initThreadViewComponent();
  }

  private initThreadViewComponent(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.threadViewComponent);
    const componentRef = this.vcRef.createComponent(componentFactory);
    componentRef.instance.scope = EThreadScope.ChangeRequest;
    componentRef.instance.changeRequest = this.changeRequest;
    componentRef.instance.unit = this.unit;
    componentRef.instance._threadId = this.changeRequest.messageThreadId;
    componentRef.instance.isModal = true;
    componentRef.instance.disabled = this.disabled;
  }
}
