import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  EThreadUnitSelectionType,
  EThreadUnitSelectionType as UnitSelectionType, EVisibleForHeaderTab,
  ITablePagination,
  pluralMap,
  ThreadUnitsWithGroupsModel, visibleForHeaderMenu,
} from '@atlas-workspace/shared/models';
import { PaginationUtil, RestThreadsService } from '@atlas-workspace/shared/service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'atl-create-thread-units-modal',
  templateUrl: './create-thread-units-modal.component.html',
  styleUrls: ['./create-thread-units-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateThreadUnitsModalComponent implements OnInit {
  @Input() public set setProjectId(projectId: number) {
    this.projectId = projectId;
    this.paginations = { ...this.defaultPagination };
    this.getList();
  }
  @Input() public set selectedUnits(list: ThreadUnitsWithGroupsModel[]) {
    this.prevSelectedUnits = cloneDeep(list);
    this.isUnitGroupSelected = this.prevSelectedUnits.some(item => item.type === UnitSelectionType.UnitGroup);
  }
  @Input() public set onSearch(_term: string) {
    this.search = _term;
    this.searchValueChangesHandler();
  }
  @Output() public readonly selectHandler = new EventEmitter<ThreadUnitsWithGroupsModel[]>();
  private projectId!: number;
  private prevSelectedUnits: ThreadUnitsWithGroupsModel[] = [];
  public lists: ThreadUnitsWithGroupsModel[] = [];
  public searchableList: ThreadUnitsWithGroupsModel[] = [];

  public search = '';
  public isLoading = false;
  public isFirstLoading = true;
  public isSearchable = false;
  public isUnitGroupSelected = false;
  public readonly selectionType: typeof UnitSelectionType = UnitSelectionType;
  public readonly unitPluralMap = pluralMap.get('unit');
  public readonly unitGroupPluralMap = pluralMap.get('unit_group');
  public readonly dataOutsideClickArea = '[data-units-outside-click-area]';
  public paginations!: ITablePagination;
  public searchablePaginations!: ITablePagination;
  private readonly defaultPagination: ITablePagination = {
    ...PaginationUtil.defaultPagination,
    pageItems: 1000,
    currentPage: 0,
  };

  public activeTab = EVisibleForHeaderTab.Units;
  public navMenu = visibleForHeaderMenu;
  public unitSelectAll = false;
  public unitGroupSelectAll = false;

  constructor(private restThreadsService: RestThreadsService, private cdr: ChangeDetectorRef) {
    this.paginations = { ...this.defaultPagination };
    this.searchablePaginations = { ...this.defaultPagination };
  }

  ngOnInit(): void {
    this.searchValueChangesHandler();
  }

  public selectTab(e: { tabName: EVisibleForHeaderTab }): void {
    if (e.tabName === this.activeTab) {
      return;
    }
    this.activeTab = e.tabName;
    this.cdr.detectChanges();
  }

  private searchValueChangesHandler(): void {
    if (this.isLoading) {
      return;
    }
    let term = this.search;
    if (term !== '' && !term.trim()) {
      return;
    }
    term = term.trim();
    this.searchablePaginations = { ...this.defaultPagination };

    this.isLoading = true;
    this.isSearchable = Boolean(term);
    this.searchableList = [];
    this.getList(false, term, true);
  }

  public get allUnits(): ThreadUnitsWithGroupsModel[] {
    const initialSelectedUnits = this.prevSelectedUnits.filter((el) => el.type === EThreadUnitSelectionType.Unit);
    const currentSelectedUnits = this.lists.filter((el) => el.type === EThreadUnitSelectionType.Unit);
    return [...initialSelectedUnits, ...currentSelectedUnits];
  }

  public get allUnitGroup(): ThreadUnitsWithGroupsModel[] {
    const initialSelectedUnits = this.prevSelectedUnits.filter((el) => el.type === EThreadUnitSelectionType.UnitGroup);
    const currentSelectedUnits = this.lists.filter((el) => el.type === EThreadUnitSelectionType.UnitGroup);
    return [...initialSelectedUnits, ...currentSelectedUnits];
  }

  public get unitsSelected(): number {
    const initialSelectedUnits = this.prevSelectedUnits.filter((el) => el.selected && el.type === EThreadUnitSelectionType.Unit);
    const currentSelectedUnits = this.lists.filter((el) => el.selected && el.type === EThreadUnitSelectionType.Unit);
    return [...initialSelectedUnits, ...currentSelectedUnits].length;
  }

  public get unitGroupSelected(): number {
    const initialSelectedUnits = this.prevSelectedUnits.filter((el) => el.selected && el.type === EThreadUnitSelectionType.UnitGroup);
    const currentSelectedUnits = this.lists.filter((el) => el.selected && el.type === EThreadUnitSelectionType.UnitGroup);
    return [...initialSelectedUnits, ...currentSelectedUnits].length;
  }

  public onSelect(): void {
    const initialSelectedUnits = this.prevSelectedUnits.filter((el) => el.selected);
    const currentSelectedUnits = this.lists.filter((el) => el.selected);
    const filteredList = initialSelectedUnits.filter(i => !currentSelectedUnits.some(c => c.id === i.id));
    this.selectHandler.emit([...filteredList, ...currentSelectedUnits]);
  }

  public clearAll(): void {
    this.lists.forEach((el) => (el.selected = false));
    this.prevSelectedUnits.forEach((el) => (el.selected = false));
    this.unitSelectAll = false;
    this.unitGroupSelectAll = false;
    this.selectHandler.emit([]);
    queueMicrotask(() => this.cdr.detectChanges());
  }

  public get selectedCount(): number {
    return this.prevSelectedUnits.filter((el) => el.selected).length;
  }

  public getNextPage(last: boolean): void {
    if (!last || this.isLoading) {
      return;
    }
    if (this.isSearchable && !this.searchablePaginations.currentPage) {
      return;
    }
    this.getList(true, this.search);
  }

  public check(item?: ThreadUnitsWithGroupsModel): void {
    if (this.isLoading) {
      return;
    }
    if (item) {
      const checked = !item.selected;
      item.selected = checked;
      this.prevSelectedUnits.forEach(
        (el) => {
          if (el.id === item.id) {
            el.selected = item.selected;
          }
        }
      );
      this.isUnitGroupSelected = checked && item.type === UnitSelectionType.UnitGroup;
      if (checked) {
        if (item.type === UnitSelectionType.UnitGroup) {
          this.lists.forEach((el) => {if (el.type === UnitSelectionType.Unit && el.selected) el.selected = false;});
          this.prevSelectedUnits.forEach((el) => {if (el.type === UnitSelectionType.Unit && el.selected) el.selected = false;});
          if (!this.unitGroupSelectAll) this.unitGroupSelectAll = true;
          if (this.unitSelectAll) this.unitSelectAll = false;
        } else {
          this.lists.forEach((el) => {if (el.type === UnitSelectionType.UnitGroup && el.selected) el.selected = false;});
          this.prevSelectedUnits.forEach((el) => {if (el.type === UnitSelectionType.UnitGroup && el.selected) el.selected = false;});
          if (!this.unitSelectAll) this.unitSelectAll = true;
          if (this.unitGroupSelectAll) this.unitGroupSelectAll = false;
        }
      }
      if (this.isSearchable) {
        this.pushSearchableItem(item);
      }
    }
    this.onSelect();
    queueMicrotask(() => this.cdr.detectChanges());
  }

  public checkAll(): void {
    if (this.activeTab === EVisibleForHeaderTab.Units) {
      this.unitSelectAll = !this.unitSelectAll;
      this.unitGroupSelectAll = false;
      this.isUnitGroupSelected = false;
      this.allUnits.forEach((el) => el.selected = this.unitSelectAll);
      this.allUnitGroup.forEach(el => el.selected = false);
    } else {
      this.unitGroupSelectAll = !this.unitGroupSelectAll;
      this.unitSelectAll = false;
      this.isUnitGroupSelected = true;
      this.allUnitGroup.forEach(el => el.selected = this.unitGroupSelectAll);
      this.allUnits.forEach((el) => el.selected = false);
    }
    this.onSelect();
  }

  private getList(expand = false, term?: string, isSearchValueChanged?: boolean): void {
    if (!this.projectId) {
      return;
    }
    const _paginations = term ? this.searchablePaginations : this.paginations;
    if (_paginations.currentPage >= _paginations.totalPages) {
      this.isLoading = false;
      this.cdr.detectChanges();
      return;
    }

    if (isSearchValueChanged && !term) {
      this.isSearchable = false;
      this.isLoading = false;
      this.cdr.detectChanges();
      return;
    }
    _paginations.currentPage++;

    this.isSearchable = Boolean(term);

    this.isLoading = true;

    this.restThreadsService
      .getUnitsWithGroups(this.projectId, term, _paginations)
      .pipe(take(1))
      .subscribe((res) => {
        _paginations.currentPage = res[1].currentPage;
        _paginations.totalCount = res[1].totalCount;
        _paginations.totalPages = res[1].totalPages;

        const filteredList = res[0].map((el) => this.replaceItemInstanceIfExist(el));
        if (expand) {
          const _filteredList = filteredList.filter(
            (el) => this.isSearchable || !this.prevSelectedUnits.find((u) => u.id === el.id && el.type === u.type)
          );
          if (this.isSearchable) {
            this.searchableList.push(..._filteredList);
          } else {
            this.lists.push(..._filteredList);
            this.searchableList = [];
          }
        } else {
          if (this.isSearchable) {
            this.searchableList = filteredList;
          } else {
            this.lists = filteredList;
            this.searchableList = [];
          }
        }
        this.unitSelectAll = this.unitsSelected === this.allUnits.length;
        this.isLoading = false;
        !this.isFirstLoading || (this.isFirstLoading = false);
        this.cdr.detectChanges();
      });
  }

  public get loopList(): ThreadUnitsWithGroupsModel[] {
    if (this.isSearchable) {
      return this.filterTabList(this.searchableList);
    } else {
      this.lists.forEach(l => l.selected = this.prevSelectedUnits.some(i => i.id === l.id));
      return this.filterTabList(this.lists);
    }
  }

  private filterTabList(list:  ThreadUnitsWithGroupsModel[]):  ThreadUnitsWithGroupsModel[] {
    return list.filter(item => item.type === (this.activeTab === EVisibleForHeaderTab.Units ? EThreadUnitSelectionType.Unit : EThreadUnitSelectionType.UnitGroup));
  }

  private pushSearchableItem(item: ThreadUnitsWithGroupsModel): void {
    const existedListItem = this.lists.find((el) => el.id === item.id && el.type === item.type);
    const existedInitListItem = this.prevSelectedUnits.find((el) => el.id === item.id && el.type === item.type);
    if (existedListItem) {
      existedListItem.selected = item.selected;
    } else if (existedInitListItem) {
      existedInitListItem.selected = item.selected;
    } else if (item.selected) {
      this.prevSelectedUnits.unshift(item);
    }
  }

  private replaceItemInstanceIfExist(item: ThreadUnitsWithGroupsModel): ThreadUnitsWithGroupsModel {
    const existedListItem = this.lists.find((el) => el.id === item.id && el.type === item.type);
    const existedInitListItem = this.prevSelectedUnits.find((el) => el.id === item.id && el.type === item.type);

    if (existedListItem) {
      return existedListItem;
    } else if (existedInitListItem) {
      return existedInitListItem;
    }
    return item;
  }
}
