import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FileDragNDropModule, SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import {
  CustomTextEditorModule,
  EditorFileUploadModule,
  TextEditorMultiFileUploadModule,
  TitledCheckboxModule,
} from '@atlas-workspace/shared/form';
import {
  SharedPipesModule,
  ThreadMessageChangeRequestPipe,
  ThreadMessageDatePipe,
  ThreadMessagesDividerByDatePipe
} from '@atlas-workspace/shared/pipes';
import { ReclamationAdminDetailModule, ReclamationClientDetailModule } from '@atlas-workspace/shared/reclamation';
import {
  CustomIconComponent, DefaultDropdownModule, FirmIconComponent,
  FloorPlanCardsComponent, HeaderIconComponent,
  NewestDocumentCardComponent,
} from '@atlas-workspace/shared/ui';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { MentionsTextEditorModule } from '../mentions-text-editor/mentions-text-editor.module';
import { ThreadEditorWrapperComponent } from '../thread-editor-wrapper/thread-editor-wrapper.component';
import { ThreadMessageComponent } from '../thread-message/thread-message.component';
import { ThreadViewRecipientsModule } from '../thread-view-recipients/thread-view-recipients.module';
import { ThreadViewComponent } from './thread-view.component';

@NgModule({
  declarations: [ThreadViewComponent, ThreadMessageComponent, ThreadEditorWrapperComponent],
  exports: [ThreadViewComponent, ThreadMessageComponent, ThreadEditorWrapperComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    CustomTextEditorModule,
    EditorFileUploadModule,
    ThreadMessagesDividerByDatePipe,
    ThreadMessageDatePipe,
    FileDragNDropModule,
    SharedDirectivesModule,
    NgbTooltipModule,
    ReclamationAdminDetailModule,
    ReclamationClientDetailModule,
    ThreadViewRecipientsModule,
    TextEditorMultiFileUploadModule,
    MentionsTextEditorModule,
    FloorPlanCardsComponent,
    TitledCheckboxModule,
    ThreadMessageChangeRequestPipe,
    NewestDocumentCardComponent,
    CustomIconComponent,
    DefaultDropdownModule,
    HeaderIconComponent,
    FirmIconComponent,
    SharedPipesModule
  ],
})
export class ThreadViewModule {}
