<!--
  ******************************************************
  @Cypress
  - interaction with form controls
  - interaction with submit buttons
  ******************************************************
-->
<atl-ngb-modal-header (closeModalEvent)="modalRef.dismiss()" [title]="'Button.New_reclamation'"></atl-ngb-modal-header>

<div class="reclamation-create__container v-scrollbar p-24" atlWindowInnerHeight>
  <form class="reclamation-create__form" [formGroup]="form" tabindex="0">
    <!-- Status -->
    <div class="reclamation-create__control">
      <div class="reclamation-create__control-title">
        <img [src]="'assets/spinner-gray.svg'" alt="icon" />{{ 'Shared.Reclamation.Input.Status.Label' | translate }}
      </div>
      <div class="reclamation-create__control-input">
        <atl-drop-down-v2
          #statusField
          class="multiple-select-component status"
          data-cy="cy-reclamation-create-select-status"
          formControlName="status"
          [listOfItems]="createReclamationStatusList"
          [bindLabel]="'title'"
          [placeholder]="'Shared.Reclamation.Input.Status.Placeholder'"
          [notFoundText]="'Shared.Entity.Not_found'"
          [useCustomItemView]="true"
          [useCustomLabelView]="true"
          [createNotFound]="false"
          [searchable]="false"
          [clearable]="false"
        >
          <ng-template atlInputLabelTemplate atlInputTemplate let-item="item">
            <div class="d-flex align-items-center justify-content-between" [attr.data-cy-id]="item.value">
              <span
                class="fz-14 lh-24 pl-8 text-black-960 ellipsis reclamation-create__status"
                [ngClass]="'reclamation-create__status-' + statusList[item?.value]?.style"
              >
                <span class="reclamation-create__status-indicator"></span>
                {{ item?.title | translate }}
              </span>
              <img
                [class.d-block]="item.value === form.get('status')?.value[0]?.value"
                class="multiple-select-user-card-checkbox"
                src="/assets/multiple_select_checkbox.svg"
                alt="Check state"
              />
            </div>
          </ng-template>
        </atl-drop-down-v2>
      </div>
    </div>
    <!-- Responsible -->
    <div class="reclamation-create__control">
      <div class="reclamation-create__control-title capitalize">
        <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{
          'Shared.Reclamation.Input.Responsible.Label' | translate
        }}
      </div>
      <div class="reclamation-create__control-input">
        <atl-drop-down-v2
          class="multiple-select-component"
          data-cy="cy-reclamation-create-select-responsible"
          formControlName="responsible"
          [listOfItems]="users"
          [bindLabel]="'name'"
          [searchParams]="['name', 'email']"
          [placeholder]="'Shared.Reclamation.Input.Responsible.Placeholder'"
          [notFoundText]="'Shared.Entity.Not_found'"
          [useCustomItemView]="true"
          [useCustomLabelView]="true"
        >
          <ng-template atlInputLabelTemplate let-item="item" let-index="index">
            <div class="multiple-select-user-card-wrapper">
              <div>
                <div
                  class="multiple-select-user-card-icon mr-4"
                  [ngStyle]="{ 'background-color': item.color, border: '1px solid ' + item.borderColor }"
                >
                  <span>{{ item?.name | firmPrefix }}</span>
                </div>
                <span
                  class="ellipsis"
                  [ngbTooltip]="item.name"
                  [openDelay]="tooltipDelay"
                  [placement]="index === 0 ? 'bottom' : 'top'"
                  [disableTooltip]="item.name.length < longNameTruncate"
                  container="body"
                  >{{ item?.name | truncate: longNameTruncate }}</span
                >
                <img
                  class="multiple-select-user-card-remove"
                  src="/assets/multiple_remove_icon.svg"
                  alt="Remove icon"
                  (click)="onRemoveSelectedItem('responsible', item)"
                />
              </div>
              <img
                class="multiple-select-user-card-checkbox"
                src="/assets/multiple_select_checkbox.svg"
                alt="Check state"
              />
            </div>
          </ng-template>
          <ng-template atlInputTemplate let-item="item" let-selected="selected" let-index="index">
            <div class="multiple-select-user-card-wrapper">
              <div>
                <div
                  class="multiple-select-user-card-icon"
                  [ngStyle]="{ 'background-color': item.color, border: '1px solid ' + item.borderColor }"
                >
                  <span>{{ item?.name | firmPrefix }}</span>
                </div>
                <div>
                  <p
                    class="reclamation-dropdown-user-name fz-14 lh-24 fw-400 ellipsis"
                    [class.selected]="selected"
                    [ngbTooltip]="item.name"
                    [openDelay]="tooltipDelay"
                    [placement]="index === 0 ? 'bottom' : 'top'"
                    [disableTooltip]="item.name.length < longNameTruncate"
                    container="body"
                  >
                    {{ item?.name | truncate: longNameTruncate }}
                  </p>
                  <p
                    class="reclamation-dropdown-user-email fz-12 lh-16 fw-400 ellipsis"
                    [ngbTooltip]="item.email"
                    [openDelay]="tooltipDelay"
                    [placement]="index === 0 ? 'bottom' : 'top'"
                    [disableTooltip]="item.email.length < longNameTruncate"
                    container="body"
                  >
                    {{ item?.email | truncate: longEmailTruncate }}
                  </p>
                </div>
                <img
                  class="multiple-select-user-card-remove"
                  src="/assets/multiple_remove_icon.svg"
                  alt="Remove icon"
                  (click)="onRemoveSelectedItem('responsible', item)"
                />
              </div>
              <img
                class="multiple-select-user-card-checkbox"
                src="/assets/multiple_select_checkbox.svg"
                alt="Check state"
              />
            </div>
          </ng-template>
        </atl-drop-down-v2>
      </div>
    </div>
    <!-- Contractor -->
    <div class="reclamation-create__control">
      <div class="reclamation-create__control-title">
        <img [src]="'assets/dropdown-circle.svg'" alt="icon" />
        <span class="d-block ellipsis" [ngbTooltip]="'Shared.Reclamation.Input.Contractor.Label' | translate">{{
          'Shared.Reclamation.Input.Contractor.Label' | translate
        }}</span>
        <img
          [ngbTooltip]="'Reclamation.Contractor.Info_tooltip' | translate"
          [openDelay]="tooltipDelay"
          placement="bottom"
          class="reclamation-create__control-info ml-8"
          src="assets/infos_icon.svg"
          alt="icon"
        />
      </div>
      <div class="reclamation-create__control-input">
        <atl-confirm-dropdown
          #contractorsField
          formControlName="contractor"
          data-cy="cy-reclamation-create-select-contractor"
          [listOfItems]="contractors"
          [searchKey]="['name', 'email', 'companyName']"
          confirmTitle="Button.Confirm_contractor"
          [bindLabel]="'id'"
          class="contractor-dropdown"
          [useCustomButtonVal]="true"
          [allowEmptyVal]="true"
          [placeholder]="'Shared.Reclamation.Input.Responsible.Placeholder' | translate"
          [useBody]="false"
          [placement]="['bottom']"
        >
          <ng-template atlInputLabelTemplate let-item="item" let-selected="selected" let-index="index">
            <div class="multiple-select-user-card-wrapper contractor-width">
              <div class="w-100 d-flex justify-content-between" *ngIf="!item?.firstKeyContact" data-cy-type="contractor">
                <div class="d-flex w-50 mr-16 min-w-0">
                  <atl-firm-icon
                    *ngIf="item"
                    [model]="item"
                    [isUser]="true"
                    [isDropdown]="true"
                    nameKey="name"
                    class="mr-8"
                  >
                  </atl-firm-icon>
                  <p
                    class="reclamation-create-dropdown-user-name fz-14 lh-24 fw-400 ellipsis"
                    [class.selected]="selected"
                    [ngbTooltip]="item?.name"
                    [openDelay]="tooltipDelay"
                    [placement]="index === 0 ? 'bottom' : 'top'"
                    [disableTooltip]="item?.name?.length < longNameTruncate"
                  >
                    {{ item?.name | truncate: longNameTruncate }}
                  </p>
                </div>
                <div class="d-flex flex-shrink-0 w-50">
                  <span class="fz-14 lh-24 fw-400 text-black-810 d-block ellipsis">
                    {{ item?.companyName }}
                  </span>
                  <img
                    class="multiple-select-user-card-remove"
                    src="/assets/multiple_remove_icon.svg"
                    alt="Remove icon"
                    (click)="onRemoveSelectedItem('contractor', item)"
                  />
                </div>
              </div>
              <div *ngIf="item?.firstKeyContact" data-cy-type="contractor-company">
                <img class="company-icon mr-8" src="assets/bag-gray.svg" alt="icon" />
                <div>
                  <p
                    class="reclamation-dropdown-user-name fz-14 lh-24 fw-400 ellipsis"
                    [class.selected]="selected"
                    [ngbTooltip]="item?.name"
                    [openDelay]="tooltipDelay"
                    [placement]="index === 0 ? 'bottom' : 'top'"
                    [disableTooltip]="item?.name?.length < longNameTruncate"
                  >
                    {{ item?.name | truncate: longNameTruncate }}
                  </p>
                </div>
                <img
                  class="multiple-select-user-card-remove"
                  src="/assets/multiple_remove_icon.svg"
                  alt="Remove icon"
                  (click)="onRemoveSelectedItem('contractor', item)"
                />
              </div>
              <img
                *ngIf="selected"
                class="item-checkbox"
                src="/assets/multiple_select_checkbox.svg"
                alt="Check state"
              />
            </div>
          </ng-template>
          <ng-template atlInputTemplate let-item="item" let-index="index">
            <div class="multiple-select-user-card-wrapper">
              <div *ngIf="!item?.firstKeyContact">
                <atl-firm-icon *ngIf="item" [model]="item" [isUser]="true" [isDropdown]="true" nameKey="name" class="mr-4">
                </atl-firm-icon>
                <span
                  class="ellipsis"
                  [ngbTooltip]="item?.name"
                  [openDelay]="tooltipDelay"
                  [placement]="index === 0 ? 'bottom' : 'top'"
                  [disableTooltip]="item?.name?.length < longNameTruncate"
                >
                  {{ item?.name | truncate: longNameTruncate }}
                  <span *ngIf="item?.companyName" class="text-black-810" data-cy-label>· {{ item?.companyName }}</span>
                </span>
                <img
                  class="multiple-select-user-card-remove"
                  src="/assets/multiple_remove_icon.svg"
                  alt="Remove icon"
                  (click)="onRemoveSelectedItem('contractor', item)"
                />
              </div>
              <div *ngIf="item?.firstKeyContact">
                <span
                  class="ellipsis"
                  [ngbTooltip]="item?.name"
                  [openDelay]="tooltipDelay"
                  [placement]="index === 0 ? 'bottom' : 'top'"
                  [disableTooltip]="item?.name?.length < longNameTruncate"
                  data-cy-label
                  >{{ item?.name | truncate: longNameTruncate }}</span
                >
                <img
                  class="multiple-select-user-card-remove"
                  src="/assets/multiple_remove_icon.svg"
                  alt="Remove icon"
                  (click)="onRemoveSelectedItem('contractor', item)"
                />
              </div>
              <img
                class="multiple-select-user-card-checkbox"
                src="/assets/multiple_select_checkbox.svg"
                alt="Check state"
              />
            </div>
          </ng-template>
        </atl-confirm-dropdown>
      </div>
    </div>
    <!-- Due_date -->
    <div class="reclamation-create__control">
      <div class="reclamation-create__control-title">
        <img [src]="'assets/calendar-gray.svg'" alt="icon" />{{ 'Shared.Reclamation.Input.Deadline.Label' | translate }}
      </div>
      <div class="reclamation-create__control-input lh-32 fz-14">
        <atl-inline-date-time-picker
          class="reclamation-create__datepicker"
          formControlName="dueDate"
          [minDate]="today"
          [dateValue]="defaultDueDate"
          [placeholder]="'Meeting_Data_Picker.Confirmation_Button' | translate"
          [secondInput]="true"
          [setDateButton]="true"
          [firstDayOfWeek]="1"
        >
        </atl-inline-date-time-picker>
      </div>
    </div>

    <div class="form-divider"></div>

    <!-- Unit -->
    <div *ngIf="!protocolId && !customUnitId" class="reclamation-create__control">
      <div class="reclamation-create__control-title">
        <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{ 'Shared.Reclamation.Input.Unit.Label' | translate }}
      </div>
      <div class="reclamation-create__control-input">
        <atl-drop-down-v2
          class="multiple-select-component"
          data-cy="cy-reclamation-create-select-unit"
          formControlName="unit"
          [listOfItems]="units"
          [bindLabel]="'identifier'"
          [placeholder]="'Input.Unit.Label'"
          [notFoundText]="'Shared.Entity.Not_found'"
          [useCustomItemView]="true"
          [useCustomLabelView]="true"
        >
          <ng-template atlInputLabelTemplate atlInputTemplate let-item="item">
            <div class="multiple-select-user-card-wrapper">
              <div>
                <span>{{ item?.identifier }}</span>
                <img
                  class="multiple-select-user-card-remove"
                  src="/assets/multiple_remove_icon.svg"
                  alt="Remove icon"
                  (click)="onRemoveSelectedItem('unit', item)"
                />
              </div>
              <img
                class="multiple-select-user-card-checkbox"
                src="/assets/multiple_select_checkbox.svg"
                alt="Check state"
              />
            </div>
          </ng-template>
        </atl-drop-down-v2>
      </div>
    </div>
    <!-- Category -->
    <div class="reclamation-create__control">
      <div class="reclamation-create__control-title">
        <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{
          'Shared.Reclamation.Input.Category.Label' | translate
        }}
      </div>
      <div class="reclamation-create__control-input">
        <atl-drop-down-v2
          class="multiple-select-component category"
          data-cy="cy-reclamation-create-select-category"
          formControlName="category"
          [listOfItems]="categories"
          [bindLabel]="'name'"
          [placeholder]="'Reclamation.Select_category'"
          [notFoundText]="'Shared.Entity.Not_found'"
          [useCustomItemView]="true"
          [useCustomLabelView]="true"
          [createNotFound]="false"
        >
          <ng-template atlInputLabelTemplate atlInputTemplate let-item="item">
            <div class="multiple-select-user-card-wrapper">
              <div>
                <span>{{ item?.name }}</span>
                <img
                  class="multiple-select-user-card-remove"
                  src="/assets/multiple_remove_icon.svg"
                  alt="Remove icon"
                  (click)="onRemoveSelectedItem('category', item)"
                />
              </div>
              <img
                class="multiple-select-user-card-checkbox"
                src="/assets/multiple_select_checkbox.svg"
                alt="Check state"
              />
            </div>
          </ng-template>
        </atl-drop-down-v2>
      </div>
    </div>
    <!-- Type -->
    <div class="reclamation-create__control">
      <div class="reclamation-create__control-title">
        <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{ 'Shared.Reclamation.Input.Type.Label' | translate }}
      </div>
      <div class="reclamation-create__control-input">
        <atl-drop-down-v2
          class="multiple-select-component type"
          data-cy="cy-reclamation-create-select-type"
          formControlName="type"
          [listOfItems]="types"
          [bindLabel]="'name'"
          [placeholder]="'Input.Product_Card.Type.Placeholder'"
          [notFoundText]="'Shared.Entity.Not_found'"
          [useCustomItemView]="true"
          [useCustomLabelView]="true"
          [createNotFound]="false"
        >
          <ng-template atlInputLabelTemplate atlInputTemplate let-item="item">
            <div class="multiple-select-user-card-wrapper">
              <div>
                <span>{{ item?.name }}</span>
                <img
                  class="multiple-select-user-card-remove"
                  src="/assets/multiple_remove_icon.svg"
                  alt="Remove icon"
                  (click)="onRemoveSelectedItem('type', item)"
                />
              </div>
              <img
                class="multiple-select-user-card-checkbox"
                src="/assets/multiple_select_checkbox.svg"
                alt="Check state"
              />
            </div>
          </ng-template>
        </atl-drop-down-v2>
      </div>
    </div>
    <!-- Description -->
    <div class="reclamation-create__control align-items-start">
      <div class="reclamation-create__control-title position-relative">
        <img [src]="'assets/text-align-left-gray.svg'" alt="icon" />{{
          'Shared.Reclamation.Input.Description.Label' | translate
        }}
      </div>
      <div class="reclamation-create__control-input">
        <atl-textarea-autoresize
          formControlName="description"
          class="newest-textarea"
          [stringMode]="true"
          [autoresizeOffset]="2"
          [placeholder]="'Input.Type_description' | translate"
          [maxLength]="500"
          data-cy="cy-reclamation-description"
          id="reclamation-description"
          [absolutePosition]="true"
        ></atl-textarea-autoresize>
      </div>
    </div>
    <ng-container *ngIf="!protocolId && !customUnitId && rooms.length">
      <!-- Room -->
      <div class="reclamation-create__control room d-none">
        <div class="reclamation-create__control-title">
          <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{ 'Shared.Reclamation.Input.Room.Label' | translate }}
        </div>
        <div class="reclamation-create__control-input">
          <atl-drop-down-v2
            class="multiple-select-component"
            data-cy="cy-reclamation-create-select-room"
            formControlName="room"
            [listOfItems]="rooms"
            [bindLabel]="'name'"
            [placeholder]="'Shared.Reclamation.Input.Room.Placeholder'"
            [notFoundText]="'Shared.Entity.Not_found'"
            [useCustomItemView]="true"
            [useCustomLabelView]="true"
          >
            <ng-template atlInputLabelTemplate atlInputTemplate let-item="item" let-index="index">
              <div class="multiple-select-user-card-wrapper">
                <div>
                  <span
                    class="ellipsis"
                    [ngbTooltip]="item.name"
                    [openDelay]="tooltipDelay"
                    [placement]="index === 0 ? 'bottom' : 'top'"
                    [disableTooltip]="item.name.length < longNameTruncate"
                    >{{ item?.name | truncate: longNameTruncate }}</span
                  >
                  <img
                    class="multiple-select-user-card-remove"
                    src="/assets/multiple_remove_icon.svg"
                    alt="Remove icon"
                    (click)="onRemoveSelectedItem('room', item)"
                  />
                </div>
                <img
                  class="multiple-select-user-card-checkbox"
                  src="/assets/multiple_select_checkbox.svg"
                  alt="Check state"
                />
              </div>
            </ng-template>
          </atl-drop-down-v2>
        </div>
      </div>
      <!-- Product -->
      <div class="reclamation-create__control d-none" *ngIf="products.length">
        <div class="reclamation-create__control-title">
          <img [src]="'assets/dropdown-circle.svg'" alt="icon" />{{
            'Shared.Reclamation.Input.Product.Label' | translate
          }}
        </div>
        <div class="reclamation-create__control-input">
          <atl-drop-down-v2
            class="multiple-select-component"
            data-cy="cy-reclamation-create-select-product"
            formControlName="product"
            [listOfItems]="products"
            [bindLabel]="'name'"
            [placeholder]="'Shared.Reclamation.Input.Product.Placeholder'"
            [notFoundText]="'Shared.Entity.Not_found'"
            [useCustomItemView]="true"
            [useCustomLabelView]="true"
          >
            <ng-template atlInputLabelTemplate let-item="item" let-index="index">
              <div class="multiple-select-user-card-wrapper">
                <div>
                  <div class="fw-400 lh-24 fz-14">
                    <img
                      [src]="item?.preview ? item.preview.filename.w260.url : 'assets/no_images.png'"
                      class="reclamation-create-dropdown-label__product br-8 mr-8 object-fit-cover"
                      alt="Image item"
                    />
                    <span
                      class="ellipsis"
                      [ngbTooltip]="item.name"
                      [openDelay]="tooltipDelay"
                      [placement]="index === 0 ? 'bottom' : 'top'"
                      [disableTooltip]="item.name.length < longNameTruncate"
                      >{{ item?.name | truncate: longNameTruncate }}</span
                    >
                  </div>
                  <img
                    class="multiple-select-user-card-remove"
                    src="/assets/multiple_remove_icon.svg"
                    alt="Remove icon"
                    (click)="onRemoveSelectedItem('product', item)"
                  />
                </div>
              </div>
            </ng-template>
            <ng-template atlInputTemplate let-item="item" let-index="index">
              <div class="multiple-select-user-card-wrapper">
                <div>
                  <div class="fw-400 lh-24 fz-14">
                    <img
                      [src]="item?.preview ? item.preview.filename.w260.url : 'assets/no_images.png'"
                      class="reclamation-create-dropdown-item__product br-8 mr-8 object-fit-cover"
                      alt="Image item"
                    />
                    <span
                      class="text-black-960 ellipsis"
                      [ngbTooltip]="item.name"
                      [openDelay]="tooltipDelay"
                      [placement]="index === 0 ? 'bottom' : 'top'"
                      [disableTooltip]="item.name.length < longNameTruncate"
                      >{{ item?.name | truncate: longNameTruncate }}</span
                    >
                  </div>
                  <img
                    class="multiple-select-user-card-remove"
                    src="/assets/multiple_remove_icon.svg"
                    alt="Remove icon"
                    (click)="onRemoveSelectedItem('product', item)"
                  />
                </div>
                <img
                  class="multiple-select-user-card-checkbox"
                  src="/assets/multiple_select_checkbox.svg"
                  alt="Check state"
                />
              </div>
            </ng-template>
          </atl-drop-down-v2>
        </div>
      </div>
    </ng-container>
    <!-- Reported -->
    <div *ngIf="!protocolId && !customUnitId" class="reclamation-create__control">
      <div class="reclamation-create__control-title">
        <img [src]="'assets/calendar-gray.svg'" alt="icon" />{{ 'Shared.Reclamation.Input.Reported.Label' | translate }}
      </div>
      <div class="reclamation-create__control-input">
        <span class="ml-8 lh-32 fz-14">{{ form.controls['reported'].value | date: 'dd.MM.yyyy · H:mm' }}</span>
      </div>
    </div>
    <!-- Attachments -->
    <div class="reclamation-create__control">
      <div class="reclamation-create__control-title">
        <img [src]="'assets/attachment_gray.svg'" alt="icon" />{{
          'Shared.Reclamation.Input.Attachments.Label' | translate
        }}
      </div>
      <div class="reclamation-create__control-input lh-32 fz-14">
        <atl-dropdown-input-file
          data-cy="cy-reclamation-create-attachments"
          [placeholder]="'Shared.Entity.Add_attachments' | translate"
          [newOnly]="true"
          [autoSave]="true"
          [format]="acceptedExtensions"
          [previewAttachment]="preloadedFiles"
          (addNewFilesToExisting)="setAttachmentCount($event)"
          (addCreateNewFilesToExisting)="tempFileUpload($event)"
          [isLoading]="isLoadingFiles"
          (updateFilePositionsEmitter)="updateFilePositions($event)"
          (viewTempFilesEmitter)="openPreview($event)"
          (deleteTempFile)="removeTempFile($event)"
        ></atl-dropdown-input-file>
      </div>
    </div>

    <ng-container *ngIf="form.value.unit.length">
      <atl-plan-mark
        [floorPlan]="floorPlanData"
        (setMarkEvent)="setMark($event)"
        (redirectToUnit)="redirectToUnit()"
      ></atl-plan-mark>
    </ng-container>
  </form>
</div>

<atl-ngb-modal-footer>
  <div class="w-100 d-flex justify-content-end">
    <button
      (click)="createReclamation(true)"
      class="newest-button newest-button--outline mr-16"
      [ngClass]="{ 'loading pointer-events-none': isDraftLoading }"
      [disabled]="!enableSaveAsDraft || fileLoading || isLoadingFiles"
      data-cy="create-draft-reclamation-button"
      type="button"
    >
      {{ 'Button.Save_as_draft' | translate }}
    </button>

    <atl-button-loader
      data-cy="create-reclamation-button"
      [disabled]="form.invalid || fileLoading || isDraftLoading || isLoadingFiles"
      (clickButton)="createReclamation()"
      [label]="'Shared.Reclamation.Button.Create'"
      [loading]="isLoading"
      [newest]="true"
      [showTooltip]="formValid"
      [tooltip]="buttonTooltip"
    ></atl-button-loader>
  </div>
</atl-ngb-modal-footer>

<ng-template #buttonTooltip>
  <p class="tooltip-title">{{ 'Shared.Reclamation.Required_fields' | translate }}:</p>
  <ul class="tooltip-ul">
    <li *ngIf="!protocolId && !customUnitId">{{ 'Shared.Reclamation.Input.Unit.Label' | translate }}</li>
    <li>{{ 'Shared.Reclamation.Input.Category.Label' | translate }}</li>
    <li>{{ 'Shared.Reclamation.Input.Type.Label' | translate }}</li>
    <li>{{ 'Shared.Reclamation.Input.Description.Label' | translate }}</li>
    <li>{{ 'Shared.Entity.Floor_plan_pin' | translate }}</li>
  </ul>
</ng-template>
