import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ChangeRequestOfferModel,
  ClientChangeRequestModel,
  EChangeRequestStatus,
  FloorModel,
  offerStatusList,
  UnitFloorModel,
  UnitUserModel,
} from '@atlas-workspace/shared/models';
import { ClientChangeRequestService } from '@atlas-workspace/shared/service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'atl-offers-list',
  templateUrl: './offers-list.component.html',
  styleUrls: ['./offers-list.component.scss', '../../../styles/status.component.scss'],
})
export class OffersListComponent implements OnInit {
  @Input() private openActiveOffer = false;
  @Input() projectId!: number;
  @Input() changeRequestId!: number;
  @Input() offers!: ChangeRequestOfferModel[];
  @Input() isDeclined!: boolean;
  @Input() public readonly unitId!: number;
  @Input() public readonly changeRequest!: ClientChangeRequestModel;
  @Input() isDualView!: boolean;
  @Input() isAlLowBankId!: boolean;
  @Input() bankIdToggle!: boolean;
  @Input() floorPlanData!: UnitFloorModel[] | FloorModel[] | any;
  @Input() unit!: UnitUserModel;
  @Input() loadOffers!: boolean;

  @Output() private readonly changeStatus = new EventEmitter<EChangeRequestStatus>();
  @Output() private readonly updateOfferList = new EventEmitter<ChangeRequestOfferModel[]>();

  public isEditMode = false;
  public editOffer!: ChangeRequestOfferModel;
  public searchParam = '';
  public sortParam = '';
  public selectedIds: number[] = [];
  public currentPage = 1;
  public isShowMessageBanner = false;
  public readonly statusClasses = offerStatusList;
  public readonly statusKeys = EChangeRequestStatus;
  public readonly pageItems = 50;

  constructor(private changeRequestService: ClientChangeRequestService) {}

  ngOnInit(): void {
    if (this.openActiveOffer) {
      const offer = this.offers.find((o) => o.id === this.changeRequest.activeOfferId);
      if (!offer) return;
      this.openDetail(offer);
    }
  }

  public updateOffer(offer: ChangeRequestOfferModel, isEditMode = false): void {
    this.isEditMode = isEditMode;
    const idx = this.offers.findIndex((o) => o.id === offer.id);
    if (idx !== -1) {
      this.offers[idx] = offer;
      this.updateOfferList.emit(this.offers);
      if (offer.status === EChangeRequestStatus.OfferApproved || offer.status === EChangeRequestStatus.OfferDeclined) {
        this.changeStatus.emit(offer.status);
      }
    }
  }

  public sort(param: string): void {
    this.sortParam = param;
    this.currentPage = 1;
    this.getOffers();
  }

  public getNextPage(last: boolean): void {
    if (last && this.offers?.length === this.currentPage * this.pageItems) {
      this.currentPage++;
      this.getOffers();
    }
  }

  private getOffers(): void {
    this.changeRequestService
      .getChangeRequestOfferList(this.unitId, this.changeRequestId, this.sortParam, this.currentPage, this.pageItems)
      .pipe(take(1))
      .subscribe((offers) => {
        this.offers = this.currentPage === 1 ? offers : [...this.offers, ...offers];
      });
  }

  public openDetail(offer: ChangeRequestOfferModel): void {
    this.isEditMode = true;
    this.editOffer = offer;
  }
}
