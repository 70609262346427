import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import { MessageModalComponent } from '@atlas-workspace/shared/form';
import { ModalHelpersService } from '@atlas-workspace/shared/modals';
import {
  acceptedGlobalExtensions,
  AdminProjectModel,
  changeReqStatusList,
  ChangeRequestModel,
  ChangeRequestOfferModel,
  EAccessTag,
  EChangeRequestStatus,
  EChangeRequestTab,
  EFirmRoles,
  EProjectRoles,
  FileModel,
  FloorModel,
  IFloorDrawToSave,
  IFloorType,
  ImageModel,
  IMark,
  ISettingsMenu,
  ITablePagination,
  IThreadState,
  ThreadModel,
  UnitFloorModel,
  UnitUserModel,
} from '@atlas-workspace/shared/models';
import {
  CableService,
  ChangeReqHelperService,
  ChangeRequestService,
  CustomNotesService,
  LockFieldService,
  ModalFacadeService,
  PaginationUtil,
  ProjectMembersService,
  RandomColorService,
  ReclamationAdminService,
  SharedUiStorageService,
  ThreadsHelperService,
} from '@atlas-workspace/shared/service';
import { TextEditorData } from '@atlas-workspace/shared/ui';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, expand, finalize, map, reduce, take, takeUntil } from 'rxjs/operators';

//Move it to shared!
import { EChangeRequestFormKey, IUpdateTable, navigationMenu } from '../../../helpers/change-request-admin.helper';

@UntilDestroy()
@Component({
  selector: 'atl-detail-change-request',
  templateUrl: './detail-change-request.component.html',
  styleUrls: ['./detail-change-request.component.scss', '../../../styles/status.component.scss'],
  providers: [ChangeRequestService, CustomNotesService],
})
export class DetailChangeRequestComponent implements OnInit, OnDestroy {
  @Input() public changeRequest!: ChangeRequestModel;
  @Input() public readonly modalRef!: NgbModalRef;
  @Input() readonly projectId!: string;
  @Input() readonly projectName!: string;
  @Input() readonly unitId!: number;
  @Input() private navigationMenuID?: string;
  @Input() modalView = true;
  @Input() hideCustomerCommunication = false;
  @Input() readonly threadViewComponent!: any;

  @Output() private readonly updateTable = new EventEmitter<IUpdateTable>();
  @Output() private readonly changeThreadStateEvent = new EventEmitter<IThreadState>();
  @Output() private readonly closeHandler = new EventEmitter();

  public form!: FormGroup;
  public formNotes!: FormGroup;
  public users: AdminProjectModel[] = [];
  public activeTab = EChangeRequestTab.General;
  public navigationMenu: ISettingsMenu[] = cloneDeep(navigationMenu);
  public readonly requestTab = EChangeRequestTab;
  public readonly descriptionMaxLength = 500;
  public readonly acceptedExtensions = acceptedGlobalExtensions;
  public readonly floorType = IFloorType;
  public readonly nameTruncate = 30;

  public offers!: ChangeRequestOfferModel[];

  public floorPlanData!: UnitFloorModel[] | FloorModel[] | any;
  public mark?: IMark[];
  public readonly apiKey = this.environment.tinyMceApiKey;
  public editorInitConfig = {
    ...TextEditorData.wideEditorConfig(this.translateService),
    placeholder: this.translateService.instant('Entity.Start_writing'),
  };

  private drawingData: IFloorDrawToSave[] = [];
  public isLoading = false;
  public statusKeys = EChangeRequestStatus;
  public statusList = changeReqStatusList;
  public makeOfferModal$ = new BehaviorSubject<boolean | null>(null);
  public isShowMessageBanner = false;
  public readonly messageBannerText = 'Option.Newest_Downloading_process';
  public fileLoading = false;
  public unit!: UnitUserModel;
  public loadingDocument = false;
  private _cancelDownload$ = new Subject<void>();

  constructor(
    private reclamationService: ReclamationAdminService,
    private router: Router,
    private changeRequestService: ChangeRequestService,
    private modalFacadeService: ModalFacadeService,
    private sharedUiStorageService: SharedUiStorageService,
    private translateService: TranslateService,
    private modalHelpersService: ModalHelpersService,
    private threadsHelperService: ThreadsHelperService,
    private cableService: CableService,
    private randomColorService: RandomColorService,
    private customNotesService: CustomNotesService,
    private readonly projectMembersService: ProjectMembersService,
    private readonly lockFieldService: LockFieldService,
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private readonly changeReqHelperService: ChangeReqHelperService,
  ) {}

  ngOnInit(): void {
    this.getOffers();
    this.getUsers();
    this.initForm();
    this.initNotesForm();
    if (this.changeRequest.unit.id) this.getUnitDetails(this.changeRequest.unit.id);
    this.updateThreadCount();
    this.changeMenu();
    this.subscribeToCableThreadsSnapshots();
    this.subscribeToThreadReadStateChange();
    this.subscribeToUnreadEvent();
    this.initNotes();

    if (this.navigationMenuID) {
      const tab = this.navigationMenu.find((item) => item.id === this.navigationMenuID);
      if (tab && !tab.disabled) {
        this.activeTab = tab.name as EChangeRequestTab;
      }
    }

    if (this.hideCustomerCommunication) {
      this.navigationMenu = this.navigationMenu.filter((i) => i.name !== EChangeRequestTab.Customer);
    }

    this.subscribeToTabEvents();
  }

  ngOnDestroy(): void {
    this._cancelDownload$.next();
    this._cancelDownload$.complete();
    this.lockFieldService.setLocked(false);
  }

  private subscribeToTabEvents(): void {
    this.changeReqHelperService.openDetailsTabEvent$.pipe(untilDestroyed(this)).subscribe(() => {
      this.activeTab = EChangeRequestTab.General;
    });
  }

  private initNotes(): void {
    if (!this.changeRequest.customNoteId) return;
    this.customNotesService
      .getNote(+this.projectId, this.changeRequest.customNoteId)
      .pipe(take(1))
      .subscribe((note) => {
        this.formNotes.get('notes')?.setValue(note, { emitEvent: false, onlySelf: true });
      });
  }

  private subscribeToUnreadEvent(): void {
    this.threadsHelperService.unreadThreadEvent.pipe(untilDestroyed(this)).subscribe(() => {
      this.activeTab = EChangeRequestTab.General;
    });
  }

  public closeDocumentLoading(): void {
    this._cancelDownload$.next();
    this.loadingDocument = false;
  }

  public get noOfferDecline(): boolean {
    return !this.offers?.some((offer) => {
      return offer.status === EChangeRequestStatus.OfferDeclined;
    });
  }

  public get requestStatus(): boolean {
    return (
      (this.changeRequest.status === this.statusKeys.New ||
        this.changeRequest.status === this.statusKeys.InProgress ||
        this.changeRequest.status === this.statusKeys.RequestDeclined ||
        this.changeRequest.status === this.statusKeys.OfferCanceled) &&
      this.noOfferDecline
    );
  }

  private getOffers(): void {
    this.changeRequestService
      .getChangeRequestOffers(this.projectId, this.changeRequest.id, '', 1, 100)
      .pipe(take(1))
      .subscribe((offers) => {
        this.offers = offers;
      });
  }

  public get isAlternative(): boolean {
    const notPlan = this.floorPlanData.filter((floor: any) => !floor.plan).length === this.floorPlanData.length;
    return !(
      this.changeRequest.status === this.statusKeys.RequestDeclined ||
      this.changeRequest.status === this.statusKeys.Approved ||
      this.changeRequest.status === this.statusKeys.OfferApproved ||
      notPlan
    );
  }

  public activityPage(): void {
    this.activeTab = EChangeRequestTab.Activities;
  }

  public updateFloorPlanDraw(v: IFloorDrawToSave[]): void {
    this.drawingData = v;
    this.updateChaneRequest();
  }

  private updateThreadCount(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.navigationMenu.find((item) => item.name === EChangeRequestTab.Customer)!.counter =
      this.changeRequest.messageThreadUnreadCount;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.navigationMenu.find((item) => item.name === EChangeRequestTab.Internal)!.counter =
      this.changeRequest.notesMessageThreadUnreadCount;

    this.navigationMenu = [...this.navigationMenu];
  }

  private changeMenu(): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.navigationMenu.find((item) => item.name === EChangeRequestTab.Offer)!.disabled =
      this.changeRequest.status === this.statusKeys.New;
    this.navigationMenu = [...this.navigationMenu];
  }

  private subscribeToThreadReadStateChange(): void {
    this.threadsHelperService
      .getThreadStateReadId()
      .pipe(untilDestroyed(this))
      .subscribe((id) => {
        if (id === this.changeRequest?.notesMessageThreadId) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.navigationMenu.find((nav) => nav.name === EChangeRequestTab.Internal)!.counter = 0;
          this.navigationMenu = [...this.navigationMenu];
        }
        if (id === this.changeRequest?.messageThreadId) {
          this.changeRequest.messageThreadUnreadCount = 0;
          this.changeRequest.messageThreadState.read = true;
          const state = this.changeRequest.messageThreadState;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.navigationMenu.find((nav) => nav.name === EChangeRequestTab.Customer)!.counter = 0;
          this.navigationMenu = [...this.navigationMenu];
          this.changeThreadStateEvent.emit(state);
        }
      });
  }

  private initForm(): void {
    this.form = new FormGroup({
      description: new FormControl(this.changeRequest.description),
      responsible: new FormControl(this.changeRequest.responsible, Validators.required),
      fileResources: new FormControl(this.changeRequest.fileResources),
    });

    this.initChangeForm();
  }

  private initChangeForm(): void {
    const keys = Object.keys(this.form.getRawValue());

    this.form.valueChanges.pipe(untilDestroyed(this), debounceTime(300), distinctUntilChanged()).subscribe((value) => {
      keys.forEach((key) => {
        if (key === EChangeRequestFormKey.Descriptions && value[key] !== this.changeRequest.description) {
          this.updateChaneRequest();
        }

        if (key === EChangeRequestFormKey.Responsible && value[key]?.[0]?.id !== this.changeRequest.responsible?.id) {
          this.updateChaneRequest();
        }
      });
    });
  }

  private initNotesForm(): void {
    this.formNotes = new FormGroup({
      notes: new FormControl(''),
    });

    this.formNotes
      .get('notes')
      ?.valueChanges.pipe(untilDestroyed(this), debounceTime(500), distinctUntilChanged())
      .subscribe((v: string) => {
        this.setNotes(v);
      });
  }

  private setNotes(value: string): void {
    this.changeRequestService
      .setNotesChangeRequest(this.projectId, this.changeRequest.id, value)
      .pipe(take(1))
      .subscribe((changeRequest) => {
        this.changeRequest = changeRequest;
      });
  }

  public addNewFiles(files: File[]): void {
    this.updateChaneRequest(files);
  }

  public deleteExistingFile(file: FileModel | ImageModel): void {
    this.updateChaneRequest([file], true);
  }

  updateChaneRequest(files: File[] | (FileModel | ImageModel)[] = [], destroy = false): void {
    const description = this.form.get('description')?.value;
    const responsible = this.form.get('responsible')?.value?.[0]?.id.toString() || '';
    this.fileLoading = true;
    this.changeRequestService
      .updateChangeRequest(
        this.projectId,
        this.changeRequest.id,
        description,
        responsible,
        files,
        destroy,
        this.drawingData,
      )
      .pipe(
        take(1),
        finalize(() => (this.fileLoading = false)),
      )
      .subscribe((changeRequest) => {
        this.drawingData = [];
        this.changeRequest = changeRequest;
        this.form.get('fileResources')?.setValue(this.changeRequest.fileResources, { emitEvent: false });
        this.updateTable.emit({
          descriptions: this.changeRequest.description,
          responsible: this.changeRequest?.responsible,
        });
      });
  }

  private subscribeToCableThreadsSnapshots(): void {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    this.cableService.threads$.pipe(untilDestroyed(this)).subscribe((thread) => {
      if (Number(this.projectId) !== thread.project?.id) {
        return;
      }
      // eslint-disable-next-line sonarjs/no-collapsible-if
      if (thread.id === this.changeRequest?.messageThreadId && this.activeTab !== EChangeRequestTab.Customer) {
        if (this.changeRequest) {
          this.changeRequest.messageThreadHasMessages = true;
          this.changeRequest.messageThreadUnreadCount = Number(thread.unreadCount);
          this.changeRequest.messageThreadState.answered = false;
          this.changeRequest.messageThreadState.read = false;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.navigationMenu.find((nav) => nav.name === EChangeRequestTab.Customer)!.counter = thread.unreadCount;
          this.navigationMenu = [...this.navigationMenu];
        }
      }
      if (thread.id === this.changeRequest?.notesMessageThreadId && this.activeTab !== EChangeRequestTab.Internal) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.navigationMenu.find((nav) => nav.name === EChangeRequestTab.Internal)!.counter = thread.unreadCount;
        this.navigationMenu = [...this.navigationMenu];
      }
      // eslint-disable-next-line sonarjs/no-collapsible-if
      if (thread.id && this.activeTab === EChangeRequestTab.Customer) {
        if (this.changeRequest && thread.id === this.changeRequest?.messageThreadId) {
          if (thread instanceof ThreadModel) {
            this.changeRequest.messageThreadHasMessages = true;
            this.changeRequest.messageThreadState.read = true;
            this.changeRequest.messageThreadState.answered = false;
          } else {
            this.changeRequest.messageThreadState = thread.state as IThreadState;
            this.changeRequest.messageThreadHasMessages = true;
          }
          this.changeThreadStateEvent.emit(this.changeRequest.messageThreadState);
        }
      }
    });
  }

  public openPreview(index: number): void {
    const files = this.form.get('fileResources')?.value ?? [];
    this.modalHelpersService.previewImages(files, index);
  }

  public selectAnotherTab(e: { tabName: EChangeRequestTab }): void {
    this.activeTab = e.tabName;
    this.lockFieldService.setLocked(false);
  }

  public changeStatus(): void {
    this.changeRequestService
      .getChangeRequestDetails(this.projectId, this.changeRequest.id.toString())
      .pipe(take(1))
      .subscribe((value) => {
        this.changeRequest = value;
        this.updateTable.emit({
          activeOfferPrice: this.changeRequest.activeOfferPrice,
          activeOfferId: this.changeRequest.activeOfferId,
          status: this.changeRequest.status,
          localizedStatus: this.changeRequest.localizedStatus,
        });
      });
  }

  public updateOfferList(offers: ChangeRequestOfferModel[]): void {
    if (!offers.length) {
      this.activeTab = EChangeRequestTab.General;
    }
    this.offers = offers;
    this.changeMenu();
  }

  public downloadPdf(): void {
    this.loadingDocument = true;
    this.changeRequestService
      .downloadPdf(this.projectId, this.changeRequest.id)
      .pipe(takeUntil(this._cancelDownload$))
      .subscribe((document) => {
        window.open(document.document.downloadUrl, '_self');
        this.loadingDocument = false;
      });
  }

  public hideMessageBanner(): void {
    this.isShowMessageBanner = false;
  }

  public showComment(): void {
    const modalRef = this.modalFacadeService.openModal(
      MessageModalComponent,
      this.sharedUiStorageService.modalCreateOffer,
    );

    const comment = this.changeRequest.eventLogs[this.changeRequest.eventLogs.length - 1].comment;

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.title = 'Shared.Title.Change_request_was_declined';
    modalRef.componentInstance.button = 'Shared.Yes_decline';
    modalRef.componentInstance.placeholder = 'Change_request.Decline.Modal.Placeholder';
    modalRef.componentInstance.comment = comment;
    modalRef.componentInstance.onlyView = true;
  }

  public redirectToUnit(): void {
    const url = `/base-layout/projects/specific_project/${this.projectId}/units/view/units?unitId=${this.unitId}`;
    void this.router.navigateByUrl(url);
  }

  public changeInProgress(): void {
    this.changeStatusChangeRequest(EChangeRequestStatus.InProgress);
  }

  public reopenChangeRequest(): void {
    const status = this.changeRequest.eventLogs[this.changeRequest.eventLogs.length - 2].status;

    if (status === EChangeRequestStatus.New) {
      this.changeInProgress();
      return;
    }
    this.changeStatusChangeRequest(status);
  }

  public declineModal(): void {
    const modalRef = this.modalFacadeService.openModal(
      MessageModalComponent,
      this.sharedUiStorageService.modalCreateOffer,
    );

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.title = 'Title.Change_request_decline';
    modalRef.componentInstance.button = 'Shared.Yes_decline';
    modalRef.componentInstance.placeholder = 'Change_request.Decline.Modal.Placeholder';

    modalRef.result.then((res) => {
      if (res !== undefined) {
        this.changeStatusChangeRequest(EChangeRequestStatus.RequestDeclined, res);
      }
    });
  }

  public makeOffer(): void {
    this.activeTab = EChangeRequestTab.Offer;
    this.makeOfferModal$.next(true);
  }

  private changeStatusChangeRequest(status: EChangeRequestStatus, comment = ''): void {
    this.isLoading = true;
    this.changeRequestService
      .setStatusChangeRequest(this.projectId, this.changeRequest.id, status, comment)
      .pipe(
        take(1),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe((value) => {
        if (!this.changeRequest.responsible && value.responsible) {
          this.form.get('responsible')?.setValue([value.responsible], { emitEvent: false });
          this.updateTable.emit({ responsible: value.responsible });
        }

        this.changeRequest = { ...value };
        this.changeMenu();
        const localizedStatus = value.localizedStatus;
        this.updateTable.emit({ status, localizedStatus });
      });
  }

  private getUnitDetails(id: number): void {
    this.reclamationService
      .getUnitDetails(this.projectId, id)
      .pipe(untilDestroyed(this))
      .subscribe((unit) => {
        this.unit = unit;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.navigationMenu = this.navigationMenu.map((m) => {
          if (m.name === EChangeRequestTab.Customer) {
            m.disabled = false;
          }
          return m;
        });
        if (this.unit.hasOwnFloorPlans) {
          this.floorPlanData = this.unit.unitFloors;
        } else {
          this.floorPlanData = this.unit.floors;
        }
      });
  }

  public closeModal(): void {
    this.modalView ? this.modalRef.dismiss() : this.closeHandler.emit();
  }

  getProjectAdminsRequest(pagination: ITablePagination | undefined): Observable<AdminProjectModel[]> {
    return this.projectMembersService.getProjectAdmins(
      this.projectId.toString(),
      undefined,
      undefined,
      pagination,
      [EAccessTag.ChangeRequests],
      [
        EProjectRoles.Custom,
        EProjectRoles.ProjectOwner,
        EProjectRoles.ProjectAdmin,
        EFirmRoles.Admin,
        EFirmRoles.PrimaryOwner,
      ],
      true,
    );
  }

  private getUsers(): void {
    const usersPagination = { ...PaginationUtil.defaultPagination };
    this.getProjectAdminsRequest(usersPagination)
      .pipe(
        untilDestroyed(this),
        expand((admins) => {
          if (admins.length === usersPagination.pageItems) {
            usersPagination.currentPage++;
            return this.getProjectAdminsRequest(usersPagination);
          }
          return EMPTY;
        }),
        map((users: AdminProjectModel[]) =>
          users.map((x) => {
            const name = x.name || x.email;
            const { iconBorderColor, iconColor } = this.randomColorService.getUserColors(name, 55, 50, true);
            return {
              ...x,
              name,
              color: iconColor,
              borderColor: iconBorderColor,
            };
          }),
        ),
        reduce(
          (allAdmins: AdminProjectModel[], currentAdmins: AdminProjectModel[]) => allAdmins.concat(currentAdmins),
          [],
        ),
      )
      .subscribe((users) => {
        this.users = users;
        const currentUserIndex = this.users.findIndex((u) => u.id == this.changeRequest.responsible?.id);
        if (currentUserIndex != -1) {
          this.form.get('responsible')?.setValue([this.users[currentUserIndex]], { emitEvent: false });
        }
      });
  }

  public onRemoveSelectedItem(control: string, item: any, compareProp = 'id'): void {
    const items = this.form.get(control)?.value;
    const foundIndex = items.findIndex((a: any) => a[compareProp] === item[compareProp]);
    if (foundIndex !== -1) {
      items.splice(foundIndex, 1);
      this.form.get(control)?.setValue([...items]);
    }
  }
}
