import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ImageModel } from '@atlas-workspace/shared/models';
import { SwiperOptions } from 'swiper/types';

import { IModalHeaderInterface } from '../../interfaces/modal-header.interface';

@Component({
  selector: 'atl-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ImageModalComponent {
  @Input() slides: ImageModel[] = [];
  @Input() index!: number;
  @Input() swiperConfig!: SwiperOptions;
  @Input() showTitle = true;
  @Input() useCopyImage = false;
  @Input() showDownload = true;
  
  get headerData(): IModalHeaderInterface {
    return {
      title: this.slides[this.index].name,
      subtitle: this.slides[this.index].createdAt,
      downloadLink: this.showDownload ? this.slides[this.index].fileName.downloadUrl : '',
      copyImage: this.useCopyImage,
    };
  }

  public onIndexChange(i: number): void {
    this.index = i;
  }
}
