import { Expose, Transform, Type } from 'class-transformer';

import { FileModel } from './file.model';
import { EPriceSystem } from './firm.model';
import { ImageModel } from './image.model';
import { OptionRuleModel } from './option-rules.model';
import { ITablePagination } from './table-pagination.interface';
import { EPriceOfferItemStatus } from './price-offer.model';

export interface IProduct {
  title: string;
  description?: string;
  id: number;
  priceType: string;
  markup: number;
  priceRounding: number;
  price: number;
  customerPrice: number;
  customerPriceWithVat: number;
  productTypeId: number;
  companyId: number;
  imagesAttributes: File[];
  fileResourcesAttributes?: File[];
}

export type TProductSortParams =
  | 'default'
  | 'title_asc'
  | 'title_desc'
  | 'price_asc'
  | 'price_desc'
  | 'type_asc'
  | 'type_desc'
  | 'vendor_asc'
  | 'vendor_desc';

export class ProductFilterModel {
  @Expose({ name: 'type_ids' }) type!: Array<number>;
  @Expose({ name: 'vendor_ids' }) vendor!: Array<number>;
  @Expose({ name: 'price_types' }) priceTypes!: Array<TPriceType>;
  @Expose({ name: 'label_ids' }) labelIds!: Array<number>;
  constructor(filters?: ProductFilterModel) {
    if (filters) {
      Object.assign(this, filters);
    }
  }
}

export class ProductImportStatsKey {
  created?: number;
  updated?: number;
  skiped?: number;
  errors?: number;
}

export class ProductImportStatsModel {
  @Expose({ name: 'products' })
  products!: ProductImportStatsKey;
  @Expose({ name: 'product_types' })
  productTypes!: ProductImportStatsKey;
  @Expose({ name: 'product_categories' })
  productCategories!: ProductImportStatsKey;
  @Expose({ name: 'vendors' })
  vendors!: ProductImportStatsKey;
  @Expose({ name: 'product_producers' })
  productProducers!: ProductImportStatsKey;
}

export class ProductImportsResponseModel {
  @Expose({ name: 'errors' })
  errors!: Record<number, string[]>;
  @Expose({ name: 'stats' })
  @Type(() => ProductImportStatsModel)
  stats!: ProductImportStatsModel;
}

export type TPriceType = 'fixed' | 'square_meter' | 'on_request';

export const priceType: Record<string, string> = {
  fixed: 'Shared.Entity.Fixed',
  square_meter: 'Shared.Entity.Square_meter',
  on_request: 'Shared.Entity.On_request',
};

export enum EPriceType {
  Fixed = 'fixed',
  SquareMeter = 'square_meter',
  OnRequest = 'on_request',
}

export class PaginationParamsModel {
  @Expose({ name: 'per_page' }) pageItems!: number;
  @Expose({ name: 'page' }) currentPage!: number;
  totalCount!: number;
  totalPages!: number;

  constructor(tablePagination?: ITablePagination) {
    if (tablePagination) {
      Object.assign(this, tablePagination);
    }
  }
}

export class GetProductsOptionsModel {
  sort: TProductSortParams = 'default';
  search = '';
  paginate?: PaginationParamsModel;
  filter?: ProductFilterModel;

  constructor(options?: {
    sort?: TProductSortParams;
    search?: string;
    paginate?: PaginationParamsModel;
    filter?: ProductFilterModel;
  }) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class CreateProductVendorModel {
  vendor!: { title: string };

  constructor(title: string) {
    this.vendor = { title };
  }
}

export class ProductVendorModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'title' })
  title: string;

  constructor() {
    this.id = 0;
    this.title = '';
  }
}

export class ProductProducerModel extends ProductVendorModel {}

export class CreateProductTypeModel {
  @Expose({ name: 'product_type' }) productType: { title: string };

  constructor(title: string) {
    this.productType = { title };
  }
}

export interface IProductLabelFilter {
  count: number,
  filter: string,
  name: string,
  id: number
}

export class ProductTypeModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'title' })
  title: string;
  @Expose({ name: 'title_localize' })
  titleLocalize!: string;
  @Expose({ name: 'custom_colors' })
  customColors?: boolean;
  @Expose({ name: 'default_type' })
  defaultType?: boolean;

  constructor() {
    this.id = 0;
    this.title = '';
  }
}

export class ProductPriceTypeModel {
  id = '';
  title = '';

  constructor(item: { title: string; id: string }) {
    this.title = item.title;
    this.id = item.id;
  }
}

export class ProductFileNameModel {
  @Expose() url = '';
  @Expose({ name: 'downloadUrl' }) download_url = '';
  @Type(() => ProductFileNameModel)
  @Expose({ name: 'w260' })
  w260?: ProductFileNameModel;
}

export class PreviewModel {
  @Expose() id = 0;
  @Expose() name = '';
  @Expose({ name: 'createdAt' }) created_at = '';
  @Expose() filename!: ProductFileNameModel;
}

export interface IPaginatedProductList {
  products: ProductListItemModel[];
  pagination: ITablePagination;
  totalCount: number;
  productIds: number[];
}

export class ProductCategory {
  @Expose() id!: number;
  @Expose() name!: string;
}

export class ProductTypeItem {
  @Expose() id!: number;
  @Expose() name!: string;

  @Expose({ name: 'layout_option_category' }) layoutOptionCategory!: {
    id: number;
    name: string;
  };
}

class LayoutOptionCategory {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
}

class LayoutProductType {
  @Expose({ name: 'id' })
  id!: number;

  @Expose({ name: 'name' })
  name!: string;

  @Expose({ name: 'layout_option_category' })
  @Type(() => LayoutOptionCategory)
  layoutOptionCategory!: LayoutOptionCategory;
}

export class ProductTypeOptionItem {
  @Expose({ name: 'id' })
  id!: number;

  @Expose({ name: 'title' })
  title!: string;

  @Expose({ name: 'name' })
  name!: string;

  @Expose({ name: 'layout_product_type' })
  @Type(() => LayoutProductType)
  layoutProductType?: LayoutProductType;

  @Expose()
  @Type(() => PreviewModel)
  @Transform(({ value }) => value?.filename?.versions?.w260?.url || 'assets/no_images.png', { toClassOnly: true })
  preview!: string;
}

export class RuleDependableProduct {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'itemable_name' }) itemableName!: string;
  @Expose({ name: 'kind' }) kind!: string;
  @Expose({ name: 'layout_product_type_id' }) layoutProductTypeId!: number;
  @Expose({ name: 'layout_product_type_name' }) layoutProductTypeName!: string;

  @Expose()
  @Type(() => PreviewModel)
  @Transform(({ value }) => value?.filename?.versions?.w260?.url || 'assets/newest-image-placeholder.svg', {
    toClassOnly: true,
  })
  preview!: string;

  @Expose({ name: 'price' }) price!: number;
  @Expose({ name: 'price_type' }) priceType!: string;

  @Expose({ name: 'rule_itemable_id' }) ruleItemableId!: number;
  @Expose({ name: 'rule_itemable_type' }) ruleItemableType!: string;
  @Expose({ name: 'rule_kind' }) ruleKind!: ERuleKind;
  @Expose({ name: 'standard' }) standard!: boolean;
  @Expose({ name: 'title' }) title!: string;
  @Expose({ name: 'wishlist_item_status' }) wishlistItemStatus!: string;
  @Expose({ name: 'added_to_wishlist' }) addedToWishlist!: boolean;
}

export class ProductModel {
  @Expose() id!: number;
  @Expose({ name: 'created_at' }) createdAt!: string;
  @Expose({ name: 'display_within_products' }) displayWithinProducts!: boolean;
  @Expose({ name: 'add_to_library' }) addToLibrary!: boolean;
  @Expose({ name: 'added_to_option_store' }) addedToOptionStore!: boolean;

  @Expose({ name: 'client_price' }) clientPrice!: number;
  @Expose() currency!: string;
  @Expose() title!: string;
  @Expose() description!: string;
  @Expose({ name: 'price_type' }) priceType!: TPriceType;
  @Expose() price!: number | string;
  @Expose({ name: 'internal_price' }) internalPrice!: number;
  @Expose({ name: 'customer_price' }) customerPrice!: number;
  @Expose({ name: 'product_identifier' }) productIdentifier!: string;
  @Expose({ name: 'product_url' }) productUrl!: string;
  @Expose({ name: 'labels' }) labels!: string[];
  @Expose({ name: 'product_producer' }) productProducer!: {
    id: number;
    name: string;
  };

  @Expose({ name: 'type' }) type!: {
    id: number;
    title: string;
  };

  @Expose({ name: 'product_category' }) productCategory!: {
    id: number;
    name: string;
  };

  productTypeId!: number;

  @Expose({ name: 'vendor' })
  @Type(() => ProductVendorModel)
  @Transform(({ value }) => value?.id, { toClassOnly: true })
  companyId!: ProductVendorModel | number;

  @Expose({ name: 'images' }) @Type(() => ImageModel) imagesAttributes!: ImageModel[];
  @Expose({ name: 'file_resources' }) @Type(() => FileModel) fileResourcesAttributes!: FileModel[];
  @Expose({ name: 'full_price' }) fullPrice!: number;
  @Expose({ name: 'allow_free_text' }) allowFreeText!: boolean;
  @Expose({ name: 'markup' }) markup!: string;
  @Expose({ name: 'vat' }) vat!: string;
  @Expose({ name: 'price_provider' }) priceProvider!: EPriceSystem;
  @Expose({ name: 'dependable_products' })
  @Type(() => RuleDependableProduct)
  dependableProducts!: RuleDependableProduct[];

  exclusionProducts!: RuleDependableProduct[];
  forcingProducts!: RuleDependableProduct[];

  @Expose({ name: 'price_offer' })
  @Type(() => PriceOfferModel)
  priceOffer!: PriceOfferModel | null;
  @Expose({name: 'internal_note'}) internalNote!: {
    id: number;
    note: string;
  } | null;
}

export interface IProductImport {
  addWidtPrice: boolean;
  addWithVendor: boolean;
}

export class ProductListItemModel {
  id = 0;
  title = '';
  @Expose({ name: 'price_type' }) priceType!: string;
  @Expose()
  @Type(() => Number)
  price = 0;

  currency = 'NOK';

  @Expose()
  @Type(() => PreviewModel)
  @Transform(({ value }) => value?.filename?.w260?.url || 'assets/newest-image-placeholder.svg', { toClassOnly: true })
  preview!: string;

  @Expose({ name: 'product_category' }) productCategory!: {
    id: number;
    name: string;
  };

  @Expose()
  @Type(() => ProductTypeModel)
  @Transform(
    ({ value }) => {
      // TODO remove it after products all task will be tested and released
      return value;
    },
    { toClassOnly: true },
  )
  type!: ProductTypeModel;

  @Expose()
  @Type(() => ProductVendorModel)
  @Transform(({ value }) => value, { toClassOnly: true })
  vendor!: ProductVendorModel;
  @Expose({ name: 'client_price' }) clientPrice?: number;
  @Expose({ name: 'full_price' }) fullPrice!: number;

  @Expose({ name: 'layout_option_category' }) layoutOptionCategory!: {
    id: number;
    name: string;
  };

  @Expose({ name: 'layout_product_type' }) layoutProductType!: {
    id: number;
    name: string;
  };
  @Expose({ name: 'product_producer' }) productProducer!: {
    id: number;
    name: string;
  };

  @Expose({ name: 'added_to_option_store' }) addedToOptionStore!: boolean;
}

class PriceOfferModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'price' }) price!: number;
  @Expose({ name: 'price_currency' }) priceCurrency!: string;
  @Expose({ name: 'is_offer_accepted' }) isOfferAccepted!: boolean;
  @Expose({ name: 'viewed_by_user' }) viewed!: boolean;
  @Expose({ name: 'due_date' }) dueDate!: string | null;
  @Expose({ name: 'description' }) description!: string | null;
  @Expose({ name: 'file_resources' })
  @Type(() => FileModel)
  fileResourcesAttributes!: FileModel[];
  @Expose({ name: 'status' }) status!: EPriceOfferItemStatus;
  @Expose({ name: 'other_lpt_active_offer' }) otherLptActiveOffer!: boolean;
}

export enum EwishlistItemStatus {
  NotSelected = 'not_selected',
  Completed = 'completed',
  InProgress = 'in_progress',
}

export enum ERuleKind {
  Forcing = 'forcing',
  Exclusion = 'exclusion',
}

export class DependableProduct {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'itemable_name' }) itemableName!: string;
  @Expose({ name: 'kind' }) kind!: string;
  @Expose({ name: 'layout_product_type_id' }) layoutProductTypeId!: number;
  @Expose({ name: 'rule_itemable_id' }) ruleItemableId!: number;
  @Expose({ name: 'rule_itemable_type' }) ruleItemableType!: string;
  @Expose({ name: 'rule_kind' }) ruleKind!: ERuleKind;
}

export class ProductListItemModelCPB extends ProductListItemModel {
  @Expose({ name: 'product_type_name' }) productTypeName!: string;
  @Expose({ name: 'layout_option_package_item_id' }) packageItemId!: number;
  @Expose({ name: 'layout_product_type_id' }) layoutProductTypeId!: number;
  @Expose({ name: 'standard' }) standard!: boolean;
  @Expose({ name: 'product_id' }) productId!: number;
  @Expose({ name: 'price_type' }) @Type(() => String) priceType!: string;
  @Expose({ name: 'selected' }) selected = false;
  @Expose({ name: 'isPlaceholder' }) isPlaceholder = false;
  @Expose({ name: 'added_to_wishlist' }) addedToWishlist!: boolean;
  @Expose({ name: 'allow_free_text' }) allowFreeText!: boolean;
  @Expose({ name: 'free_text' }) freeText!: string | null;
  @Expose({ name: 'added_to_package' }) addedToPackage?: boolean;
  @Expose({ name: 'typeId' }) typeId?: number | undefined;
  @Expose({ name: 'product_url' }) productUrl?: string;
  @Expose({ name: 'product_identifier' }) productIdentifier?: string;
  @Expose({ name: 'wishlist_item_status' }) status?: string;
  @Expose({ name: 'wishlist_item_id' }) wishlistItemId!: number;
  @Expose({ name: 'layout_product_type_name' }) layoutProductTypeName!: string;
  @Expose({ name: 'area_size' }) areaSize!: string;

  @Expose({ name: 'digital_showroom_version' }) digitalShowroomVersion!: string;
  @Expose({ name: 'wishlist_item_price_requested' }) wishlistItemPriceRequested!: boolean;
  @Expose({ name: 'position' }) position!: number;

  @Expose({ name: 'added_to_wishlist_by_forcing_rule_trigger_names' })
  addedToWishlistByForcingRuleTriggerNames!: string[];
  @Expose({ name: 'price_offer' })
  @Type(() => PriceOfferModel)
  priceOffer!: PriceOfferModel | null;

  @Expose({ name: 'blocked' }) blocked!: boolean;
  @Expose({ name: 'item_type' }) itemType!: string;
  @Expose({ name: 'dependable_products' })
  @Type(() => RuleDependableProduct)
  dependableProducts!: RuleDependableProduct[];

  exclusionProducts!: RuleDependableProduct[];
  forcingProducts!: RuleDependableProduct[];

  disabled = false;
  disabledProductTitle: string[] = [];
  forcedProductTitle: string[] = [];
  hasForcingProduct = false;

  rules?: OptionRuleModel[];
}

export class ProductPayloadModel {
  @Expose({ name: 'title' }) title!: string;
  @Expose({ name: 'description' }) description?: string;
  @Expose({ name: 'price_type' }) priceType!: TPriceType;

  @Expose({ name: 'price' }) price!: number;
  @Expose({ name: 'internal_price' }) internalPrice!: number;
  @Expose({ name: 'customer_price' }) customerPrice!: number;

  @Expose({ name: 'product_type_id' }) productTypeId!: number;
  @Expose({ name: 'company_id' }) companyId!: number;
  @Expose({ name: 'images_attributes' }) imagesAttributes: any;
  @Expose({ name: 'file_resources_attributes' }) fileResourcesAttributes: any;
  @Expose({ name: 'product_identifier' }) productIdentifier!: string;
  @Expose({ name: 'product_url' }) productUrl!: string;
  @Expose({ name: 'product_producer_id' }) productProducerId!: number;
  @Expose({ name: 'allow_free_text' }) allowFreeText!: boolean;
  @Expose({ name: 'display_within_products' }) displayWithinProducts!: boolean;
  @Expose({ name: 'add_to_library' }) addToLibrary!: boolean;
  @Expose({ name: 'product_category_id' }) productCategoryId!: number;

  @Expose({ name: 'markup' }) markup!: string;
  @Expose({ name: 'internal_note_attributes' }) internalNote!: string;

  constructor(model?: any) {
    if (!model) {
      return;
    }

    Object.entries(model).forEach(([key, value]) => {
      if (Array.isArray(value) && !Array.from(value).length) {
        return;
      } else if (Array.isArray(value) && Array.from(value).length) {
        Object.assign(this, { [key]: String(key) });
      } else {
        Object.assign(this, { [key]: value });
      }
    });
  }

  isImage(file: File): boolean {
    const type = file.type.split('/')[0];
    return type === 'image';
  }
}

export class ResFreeTextProductModel extends ProductModel {
  @Expose({ name: 'allow_free_text' }) allowFreeText!: boolean;
  @Expose({ name: 'free_text' }) freeText!: string | null;
}

export interface IReorderProduct {
  id: number;
  position: number;
}

export interface IExportProductsProps {
  projectId: number;
  reportType: string;
  search: string;
  sortBy: string;
  pagination: PaginationParamsModel;
  filters: Record<'type' | 'vendor', number[]>;
}
