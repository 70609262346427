import {
  EThreadFolders,
  EThreadQuickFilterKey,
  EThreadQuickFilterValue,
  IThreadFilterModel,
  ThreadsFilterItemModel,
} from '@atlas-workspace/shared/models';

import floorPlanViewIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/plan-icon.svg';
import tableViewIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/table-icon-new.svg';

export const filterData: IThreadFilterModel = {
  projects: [] as ThreadsFilterItemModel[],
  units: [] as ThreadsFilterItemModel[],
};

export const filterDataType: IThreadFilterModel = {
  type: [
    {
      id: 1,
      title: 'Messages.My_messages',
      value: EThreadQuickFilterValue.MyThreads,
      key: EThreadQuickFilterKey.MyThreads,
      selected: false,
      counter: 0,
    },
    {
      id: 2,
      title: 'Messages.Normal_messages',
      value: EThreadQuickFilterValue.Default,
      key: EThreadQuickFilterKey.Default,
      selected: true,
      counter: 0,
    },
    {
      id: 3,
      title: 'Threads.Multi_message',
      value: EThreadQuickFilterValue.MultiMessage,
      key: EThreadQuickFilterKey.MultiMessage,
      selected: false,
      counter: 0,
    },
    {
      id: 4,
      title: 'Title.Reclamations',
      value: EThreadQuickFilterValue.Reclamation,
      key: EThreadQuickFilterKey.Reclamation,
      selected: false,
      counter: 0,
    },
    {
      id: 4,
      title: 'Title.Change_requests',
      value: EThreadQuickFilterValue.ChangeRequest,
      key: EThreadQuickFilterKey.ChangeRequest,
      selected: false,
      counter: 0,
    },
  ],
};

export const reclamationViewFolders: any[] = [
  {
    title: 'Messages.Inbox',
    icon: tableViewIcon,
    name: '',
    key: EThreadFolders.All,
  },
  {
    title: 'Messages.Drafts',
    icon: floorPlanViewIcon,
    name: '',
    key: EThreadFolders.Drafts,
  },
];
