<div class="message-wrapper__container" [ngClass]="reclamationScope || ''" atlWindowInnerHeight>
  <ng-container *ngIf="reclamation?.archived">
    <div class="message-wrapper__warning">
      <div>
        <atl-custom-icon [icon]="warningIcon"></atl-custom-icon>
      </div>
      <div class="message-wrapper__warning-text fz-14 lh-24">
        <span class="fw-600">{{ 'Entity.Warning' | translate }}: </span>
        {{ 'Reclamation.Warning.Ownership_is_transferred' | translate }}
      </div>
    </div>
  </ng-container>
  <ng-template #threadView></ng-template>

  <atl-reclamation-messages-info
    *ngIf="!reclamation.archived"
    [reclamation]="reclamation"
    [projectId]="projectId"
    [isContractor]="isContractor"
  ></atl-reclamation-messages-info>
</div>
