import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { SharedFormModule, TitledCheckboxModule } from '@atlas-workspace/shared/form';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { ChangeRequestFilterComponent } from './change-request-filter/change-request-filter.component';
import { ChangeRequestQuickFilterComponent } from './change-request-quick-filter/change-request-quick-filter.component';
import {FirmIconComponent} from "@atlas-workspace/shared/ui";

@NgModule({
  imports: [
    CommonModule,
    SharedFormModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    SharedDirectivesModule,
    TitledCheckboxModule,
    FormsModule,
    FirmIconComponent
  ],
  declarations: [ChangeRequestFilterComponent, ChangeRequestQuickFilterComponent],
  exports: [ChangeRequestFilterComponent, ChangeRequestQuickFilterComponent],
})
export class ChangeRequestsFilterModule {}
