import { Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Input, OnInit, Output, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ERecDiscussionTabId, ERecDiscussionTabName, EReclamationScope, EThreadScope, ISettingsMenu, ReclamationsModel } from '@atlas-workspace/shared/models';
import { CableService, ReclamationAdminService, ThreadsHelperService } from '@atlas-workspace/shared/service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash';

import warningIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/warning-icon-tringle.svg';

import { navigationDiscussionMenu } from '../../../helpers/reclamations.helper';

@UntilDestroy()
@Component({
  selector: 'atl-reclamation-admin-internal-notes',
  templateUrl: './reclamation-admin-internal-notes.component.html',
  styleUrls: ['./reclamation-admin-internal-notes.component.scss'],
})
export class ReclamationAdminInternalNotesComponent implements OnInit {
  @Input() public threadViewComponent!: Type<any>;
  @Input() public reclamation!: ReclamationsModel;
  @Input() public reclamationScope!: EReclamationScope;
  @Input() public disabled = false;
  @Input() public isContractor!: boolean;
  @Input() public projectId!: number;
  @Input() public openTab?: ERecDiscussionTabName;

  @Output() private readonly setInternalActiveTab = new EventEmitter<ERecDiscussionTabId>();

  @ViewChild('threadView', { static: true, read: ViewContainerRef })
  private vcRef!: ViewContainerRef;
  public readonly warningIcon = warningIcon;

  public activeTab!: string;
  public navigationMenu: ISettingsMenu[] = navigationDiscussionMenu;

  private componentRef!: ComponentRef<any>;

  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly cableService: CableService,
    private readonly threadsHelperService: ThreadsHelperService,
    private readonly reclamationService: ReclamationAdminService
  ) {}

  ngOnInit(): void {
    this.initThreadViewComponent();
    this.subscribeToThreadReadStateChange();
    this.subscribeToCableThreadsSnapshots();

    if (this.openTab === ERecDiscussionTabName.AdminsOnly && !this.isContractor) {
      this.activeTab = ERecDiscussionTabName.AdminsOnly;
      this.componentRef.instance._threadId = this.reclamation.onlyAdminsThreadId;
    } else if (this.openTab === ERecDiscussionTabName.CompanyName && !this.isContractor) {
      this.activeTab = ERecDiscussionTabName.CompanyName;
      this.componentRef.instance._threadId = this.reclamation.notesMessageThreadId;
    } else {
      if (this.isContractor) {
        this.navigationMenu = this.navigationMenu.filter(i => i.id === ERecDiscussionTabId.CompanyName);
        this.activeTab = ERecDiscussionTabName.CompanyName;
      } else {
        this.activeTab = ERecDiscussionTabName.CompanyName;
      }
    }

    this.setInternalActiveTab.emit(this.activeTab === ERecDiscussionTabName.CompanyName ? ERecDiscussionTabId.CompanyName : ERecDiscussionTabId.AdminsOnly);

    if (this.reclamation) {
      if (!this.isContractor) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.navigationMenu.find((nav) => nav.id === ERecDiscussionTabId.CompanyName)!.counter = this.reclamation.onlyAdminsThreadUnreadCount;
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.navigationMenu.find((nav) => nav.id === ERecDiscussionTabId.CompanyName)!.counter = this.reclamation.notesMessageThreadUnreadCount;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.navigationMenu.find((nav) => nav.id === ERecDiscussionTabId.AdminsOnly)!.counter = this.reclamation.onlyAdminsThreadUnreadCount;
      this.cloneNavMenu();
    }
  }

  private cloneNavMenu(): void {
    this.navigationMenu = cloneDeep(this.navigationMenu);
  }

  public selectAnotherTab(e: { tabName: ERecDiscussionTabName }): void {
    if (e.tabName === this.activeTab) {
      return;
    }
    this.activeTab = e.tabName;
    this.setInternalActiveTab.emit(this.activeTab === ERecDiscussionTabName.AdminsOnly ? ERecDiscussionTabId.AdminsOnly : ERecDiscussionTabId.CompanyName);
    if (this.activeTab === ERecDiscussionTabName.AdminsOnly) {
      this.componentRef.instance._threadId = this.reclamation.onlyAdminsThreadId;
      this.componentRef.instance.scope = EThreadScope.InternalNotesAdmins;
    } else {
      this.componentRef.instance._threadId = this.reclamation.notesMessageThreadId;
      this.componentRef.instance.scope = EThreadScope.InternalNotes;
    }
  }

  private initThreadViewComponent(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.threadViewComponent);
    this.componentRef = this.vcRef.createComponent(componentFactory);
    this.componentRef.instance.scope = EThreadScope.InternalNotes;
    if (!this.openTab) this.componentRef.instance._threadId = this.reclamation.notesMessageThreadId;
    this.componentRef.instance.isModal = true;
    this.componentRef.instance.disabled = this.reclamation.archived;
  }

  private subscribeToThreadReadStateChange(): void {
    this.threadsHelperService
      .getThreadStateReadId()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.updateReclamationCounters();
      });
  }

  private subscribeToCableThreadsSnapshots(): void {
    this.cableService.threads$.pipe(untilDestroyed(this)).subscribe((thread) => {
      if (Number(this.projectId) !== thread.project?.id) {
        return;
      }
      if (
        thread.id === this.reclamation?.notesMessageThreadId && this.activeTab !== ERecDiscussionTabName.CompanyName
      ) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.navigationMenu.find((nav) => nav.id === ERecDiscussionTabId.CompanyName)!.counter = thread.unreadCount;
        if (this.reclamation) this.reclamation.notesMessageThreadUnreadCount = thread.unreadCount;
      }
      if (
        thread.id === this.reclamation?.onlyAdminsThreadId && this.activeTab !== ERecDiscussionTabName.AdminsOnly
      ) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.navigationMenu.find((nav) => nav.id === ERecDiscussionTabId.AdminsOnly)!.counter = thread.unreadCount;
        if (this.reclamation) this.reclamation.onlyAdminsThreadUnreadCount = thread.unreadCount;
      }
      this.cloneNavMenu();
    });
  }

  private updateReclamationCounters(): void {
    this.reclamationService.getReclamation(this.projectId.toString(), this.reclamation.id).pipe(untilDestroyed(this))
      .subscribe(recl => {
        this.reclamation = recl;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.navigationMenu.find((nav) => nav.id === ERecDiscussionTabId.CompanyName)!.counter = this.reclamation.notesMessageThreadUnreadCount;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.navigationMenu.find((nav) => nav.id === ERecDiscussionTabId.AdminsOnly)!.counter = this.reclamation.onlyAdminsThreadUnreadCount;
        this.cloneNavMenu();
      })
  }
}
