import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangeFormatDatePipe } from '@atl-libs-shared-pipes/change-formate-date/change-format-date.pipe';
import { SharedPipesModule } from '@atl-libs-shared-pipes/shared-pipes.module';
import { JoinStringArrayPipeModule } from '@atl-libs-shared-pipes/string-array-formatter/string-arrat-formatter.module';
import { UnderscoreReplacerModule } from '@atl-libs-shared-pipes/underscore-replacer/underscore-replacer.module';
import { DataTableModule } from '@atlas-workspace/data-table';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import {
  DrawingMarkComponent,
  FilterDropdownModule,
  InputRadioComponent,
  NewestTextEditorModule,
  SharedFormModule,
  TextEditorWideComponent,
} from '@atlas-workspace/shared/form';
import { SharedModalsModule } from '@atlas-workspace/shared/modals';
import { CustomNotesService } from '@atlas-workspace/shared/service';
import {
  ActionMessageBannerComponent,
  ButtonLoaderComponent,
  FirmIconComponent,
  HeaderIconComponent,
  NgbModalFooterComponent,
  NotFoundModule,
  ProgressLoaderModule,
  SharedTableComponent,
  SimpleTabsComponent,
  TableSortModule,
} from '@atlas-workspace/shared/ui';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { ChangeRequestActivityComponent } from './change-request-activity/change-request-activity.component';
import { ChangeRequestCustomerNotesComponent } from './change-request-customer-notes/change-request-customer-notes.component';
import { ChangeRequestInternalNotesComponent } from './change-request-internal-notes/change-request-internal-notes.component';
import { CreateOfferComponent } from './create-offer/create-offer.component';
import { DetailChangeRequestComponent } from './detail-change-request/detail-change-request.component';
import { OfferDetailComponent } from './offer-detail/offer-detail.component';
import { OffersListComponent } from './offers-list/offers-list.component';

const components = [
  DetailChangeRequestComponent,
  OffersListComponent,
  OfferDetailComponent,
  ChangeRequestCustomerNotesComponent,
  ChangeRequestInternalNotesComponent,
  ChangeRequestActivityComponent,
  CreateOfferComponent,
];

@NgModule({
  imports: [
    CommonModule,
    SharedFormModule,
    JoinStringArrayPipeModule,
    UnderscoreReplacerModule,
    SharedPipesModule,
    TableSortModule,
    ReactiveFormsModule,
    FormsModule,
    SharedDirectivesModule,
    DataTableModule,
    NgbTooltipModule,
    NotFoundModule,
    SharedModalsModule,
    FilterDropdownModule,
    ProgressLoaderModule,
    NewestTextEditorModule,
    TranslateModule,
    ChangeFormatDatePipe,
    TextEditorWideComponent,
    SimpleTabsComponent,
    ActionMessageBannerComponent,
    DrawingMarkComponent,
    ButtonLoaderComponent,
    HeaderIconComponent,
    NgbModalFooterComponent,
    FirmIconComponent,
    SharedTableComponent,
    InputRadioComponent,
  ],
  declarations: [...components],
  providers: [CustomNotesService],
  exports: [...components],
})
export class SharedChangeRequestsAdminModule {}
